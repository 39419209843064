<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} List
              </h3>
            </div>
            <div class="col text-right">
              <base-button type="primary" @click="add" size="sm"
                >Add new</base-button
              >
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="procedures"
          >
            <template v-slot:columns>
              <th>Tool</th>
              <th>Stations & Activities</th>
              <th>Template</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.tool.name }}
              </th>
              <td v-html="row.item.display_name"></td>
              <td>
                {{ row.item.template }}
              </td>
              <td class="text-right">
                <base-dropdown class="dropdown" position="right">
                  <template v-slot:title>
                    <a
                      class="btn btn-sm btn-icon-only text-light"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>
                  </template>

                  <a
                    class="dropdown-item text-green"
                    href="javascript:void(0)"
                    @click="edit(row.item)"
                    ><i class="ni ni-fat-add"></i>Edit</a
                  >
                  <a
                    class="dropdown-item text-danger"
                    href="javascript:void(0)"
                    @click="delete_item(row.item)"
                    ><i class="ni ni-fat-remove" aria-hidden="true"></i
                    >Delete</a
                  >
                </base-dropdown>
              </td>
            </template>
          </base-table>
        </div>

        <div
          class="card-footer d-flex justify-content-end"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        ></div>
      </div>
    </div>
  </div>
  <modal
    v-model:show="activitiesform"
    modal-classes="modal-dialog-centered modal-xl"
    body-classes="p-0"
  >
    <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Add new {{ title }}
            </h3>
          </div>
        </div>
      </div>
      <div class="card-body px-lg-5 py-lg-5">
        <form
          id="app"
          action="#"
          method="post"
          role="form"
          @submit="submitData"
          novalidate="true"
        >
          <base-alert v-if="show_alert" :type="alert_type" dismissible>
            <div v-for="(row, index) in alert_response" :key="row">
              <span class="alert-inner--icon"
                ><i class="fa fa-exclamation"></i
              ></span>

              <span class="alert-inner--text"
                ><strong>{{ index }}</strong> {{ row }}</span
              >
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </base-alert>
          <base-input
            formClasses="input-group-alternative mb-3"
            placeholder="Name"
            v-model="model.name"
            :valid="model.name_valid"
            @blur="model.name_valid = undefined"
            :error="model.name_error"
          >
          </base-input>
          <base-input
            formClasses="input-group-alternative mb-3"
            placeholder="Template(optional)"
            v-model="model.template"
            :valid="model.template_valid"
            @blur="model.template_valid = undefined"
            :error="model.template_error"
          >
          </base-input>
          <el-cascader
            class="input-group-alternative mb-3"
            style="width: 100%"
            :disabled="selects.disabled"
            placeholder="Select sub-speciality"
            v-model="model.activity.id"
            :options="selects.activities"
            :props="{
              expandTrigger: 'hover',
              checkStrictly: true,
            }"
            @change="setActivity"
            clearable
          >
          </el-cascader>
          <div class="input-group-alternative mb-3">
            <el-select
              style="width: 100%"
              placeholder="Select tool"
              v-model="model.tool.id"
              filterable
              :value="model.tool.id"
              @change="toolSelect"
            >
              <el-option
                v-for="option in selects.tools"
                class="select-danger"
                :value="option.id"
                :label="option.name"
                :key="option"
              >
              </el-option>
            </el-select>
          </div>

          <div class="text-right">
            <base-button nativeType="submit" type="primary" class="my-4"
              >save</base-button
            >
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>
<script>
import { ElSelect, ElOption, ElCascader } from "element-plus";
import "vue3-treeselect/dist/vue3-treeselect.css";
export default {
  name: "procedures-table",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElCascader.name]: ElCascader,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      activitiesform: false,
      procedures: [],
      selects: {
        activities: [],
        tools: [],
        tool_array: {},
      },
      model: {
        id: null,
        name: "",
        template: "",
        tool: { id: null, name: null },
        activity: { id: null },
        parent_id: null,
        isvalid: 1,
      },
    };
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  mounted: function () {
    this.getTools();
    this.getActivities();
  },
  methods: {
    add: function () {
      this.model.id = null;
      this.activitiesform = true;
    },
    toolSelect(val) {
      this.model.tool.id = val;
      this.model.tool.name = this.selects.tool_array[val];
    },
    setActivity(node) {
      this.model.activity.id = node[node.length - 1];
    },
    edit: function (record) {
      this.isvalid = 1;
      this.model = record;
      this.activitiesform = true;
    },
    delete_item: function (record) {
      this.model = record;
      this.deleteActivity();
    },
    submitData: function (e) {
      this.hasError = false;
      this.isvalid = 1;
      if (!this.model.name) {
        this.isvalid = this.isvalid * 0;
        this.model.name_valid = false;
      }
      if (!this.model.tool.id) {
        this.isvalid = this.isvalid * 0;
      }
      if (this.isvalid) {
        this.activitiesform = false;
        this.model.id != null ? this.updateActivity() : this.createActivity();
      }
      e.preventDefault();
    },
    getActivities() {
      this.$store.dispatch("api/get_activities").then(
        (response) => {
          this.selects.activities = response.data.data;
          this.generateActivityList(response.data.data, "");
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    createActivity() {
      this.$store.dispatch("api/add_activity", this.model).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          this.procedures = [];
          this.getActivities();
          this.model.id = null;
          this.model.name = null;
          this.model.template = null;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {}
      );
    },
    updateActivity() {
      this.$store.dispatch("api/update_activity", this.model).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          this.procedures = [];
          this.getActivities();
          this.model.id = null;
          this.model.name = null;
          this.model.template = null;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {}
      );
    },
    deleteActivity() {
      this.$store.dispatch("api/del_activity", this.model.id).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          var idx = this.departments.indexOf(this.model);
          this.departments.splice(idx, 1);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Record deleted successfully",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Failed to delete record.",
          });
          console.log(error);
        }
      );
    },
    getTools() {
      this.$store.dispatch("api/get_tools").then(
        (response) => {
          this.selects.tools = response.data;
          response.data.forEach((item) => {
            this.selects.tool_array[item.id] = item.name;
          });
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    generateActivityList(activities, indent) {
      activities.forEach((activity) => {
        var _id = activity.value;
        var _name = activity.label;
        var _template = activity.template;
        var _display_name = indent + activity.label;
        var _tool = activity.tool;
        var _activity = { id: activity.parent_id };
        this.procedures.push({
          name: _name,
          template: _template,
          tool: _tool,
          activity: _activity,
          display_name: _display_name,
          id: _id,
        });
        if (activity.children !== undefined) {
          this.generateActivityList(
            activity.children,
            _display_name + '<span class="text-success"> ● </span> '
          );
        }
      });
    },
  },
};
</script>
<style></style>
