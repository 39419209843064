<template>
  <div class="row justify-content-center">
    <div class="col-lg-7 col-md-9">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent px-lg-5 py-lg-5">
          <base-alert v-if="show_alert" :type="alert_type" dismissible>
            <div v-for="(row, index) in alert_response" :key="row">
              <span class="alert-inner--icon"
                ><i class="fa fa-exclamation"></i
              ></span>

              <span class="alert-inner--text"
                ><strong>{{ index }}</strong> {{ row }}</span
              >
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </base-alert>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form
            id="app"
            action="#"
            method="post"
            role="form"
            @submit="setNewPassword"
            novalidate="true"
          >
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
              :valid="model.email_valid"
              @blur="model.email_valid = undefined"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="New Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
              :valid="model.password_valid"
              @blur="model.password_valid = undefined"
            >
            </base-input>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Confirm Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.confirm_password"
              :valid="model.confirm_password_valid"
              @blur="model.confirm_password_valid = undefined"
            >
            </base-input>
            <div class="text-center">
              <base-button nativeType="submit" type="primary" class="my-4">{{
                model.submitLabel
              }}</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Login into your account</small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "new password",
  data() {
    return {
      model: {
        email: "",
        password: "",
        confirm_password: "",
        submitLabel: "Update Password",
        isvalid: 1,
        password_valid: undefined,
        confirm_password_valid: undefined,
        token: "",
        email_valid: false,
      },
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.api.status.isAuthenticated;
    },
    ErrorResponse() {
      return this.$store.state.api.api_response;
    },
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push("/profile");
    }
  },
  mounted() {
    this.model.token = this.$route.query.token;
  },

  methods: {
     validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setNewPassword: function (e) {
      e.preventDefault();
      this.model.isvalid = 1;
      if (!this.model.email || !this.validEmail(this.model.email)) {
        this.model.email_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }

      if (!this.model.password) {
        this.model.password_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.confirm_password) {
        this.model.confirm_password_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (this.model.isvalid) {
        this.model.submitLabel = "Updating password ...";
        this.$store.dispatch("api/update_password", this.model).then(
          () => {},
          // eslint-disable-next-line no-unused-vars
          (error) => {
            console.log(error);
            this.model.submitLabel = "Update Password";
          }
        );
      }
    },
  },
};
</script>
<style></style>
