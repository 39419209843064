<template>
  <div>
    <div class="row">
      <div class="col-lg-8 col-md-8 text-lead text-white">
        Watch this video to learn how to register as a student and how to submit
        cases for review.
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/OZg4Jk-A4OU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KdXFikDhiCI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 text-lead text-white">
        Watch this video to learn how to register as an internal or external
        supervisor and how to review submitted cases from students.
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-8 text-lead text-white">
        Watch this video to learn how to make payments for the monthly invoices.
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Tm9Pb_WhuJ4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  methods: {
    onReady() {
      this.$refs.youtube.playVideo();
    },
  },
};
</script>
<style></style>
