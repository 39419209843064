<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/profile-cover.jpeg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Hello {{ model.first_name }},</h1>
            <p class="text-white mt-0 mb-5 text-wrap">
              Update your profile information below.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row justify-content-center">
        <div class="col-xl-8 order-xl-1">
          <form
            id="app"
            action="#"
            method="post"
            role="form"
            @submit="updateUser"
            novalidate="true"
          >
            <card shadow type="secondary">
              <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">My account</h3>
                    </div>
                    <div class="col text-right">
                      <h3 class="mb-0">ID:{{ model.id }}</h3>
                    </div>
                  </div>
                </div>
              </template>

              <div class="card-body px-lg-5 py-lg-5">
                <div class="row">
                  <div class="col-sm">
                    <base-input
                      formClasses="input-group-alternative"
                      placeholder="First Name"
                      addon-left-text="First Name:"
                      addon-left-icon="ni ni-circle-08 text-dark"
                      v-model="model.first_name"
                      :valid="model.first_name_valid"
                      @blur="model.first_name_valid = undefined"
                    >
                    </base-input>
                  </div>
                  <div class="col-sm">
                    <base-input
                      formClasses="input-group-alternative"
                      placeholder="Middle Name"
                      addon-left-text="Middle Name:"
                      addon-left-icon="ni ni-circle-08 text-dark"
                      v-model="model.middle_name"
                      :valid="model.middle_name_valid"
                      @blur="model.middle_name_valid = undefined"
                    >
                    </base-input>
                  </div>
                  <div class="col-sm">
                    <base-input
                      formClasses="input-group-alternative"
                      placeholder="Last Name"
                      addon-left-text="Last Name:"
                      addon-left-icon="ni ni-circle-08 text-dark"
                      v-model="model.last_name"
                      :valid="model.last_name_valid"
                      @blur="model.last_name_valid = undefined"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <base-input
                      formClasses="input-group-alternative"
                      placeholder="Email"
                      addon-left-text="Email:"
                      disabled="disabled"
                      addon-left-icon="ni ni-email-83 text-dark"
                      v-model="model.email"
                      :valid="model.email_valid"
                      @blur="model.email_valid = undefined"
                    >
                    </base-input>
                  </div>
                  <div class="col-sm">
                    <vue-tel-input
                      :value="model.phone_number"
                      @validate="checkMobileNumber($event)"
                      v-bind="model.bindProps"
                    ></vue-tel-input>
                  </div>
                </div>
                <div v-if="model.showForStudent" id="student_only">
                  <base-input
                    formClasses="input-group-alternative"
                    placeholder="Student ID"
                    addon-left-text="Student ID:"
                    addon-left-icon="ni ni-hat-3 text-dark"
                    v-model="model.student_id"
                    :valid="model.student_id_valid"
                    @blur="model.student_id_valid = undefined"
                  >
                  </base-input>
                  <div class="form-group input-group-alternative">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">
                          <i class="ni ni-calendar-grid-58 text-dark"></i>
                          <span class="ml-2 text-dark"
                            >Date of birth:</span
                          ></span
                        >
                      </div>
                      <flat-picker
                        :config="{ allowInput: true }"
                        class="form-control datepicker"
                        v-model="model.date_of_birth"
                      >
                      </flat-picker>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm">
                      <base-radio
                        name="gender"
                        checked
                        class="mb-3"
                        v-model="model.gender"
                      >
                        Male
                      </base-radio>
                    </div>
                    <div class="col-sm">
                      <base-radio
                        name="gender"
                        class="mb-3"
                        v-model="model.gender"
                      >
                        Female
                      </base-radio>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <base-button
                    nativeType="submit"
                    type="primary"
                    class="my-4"
                    >{{ model.updateAccountLabel }}</base-button
                  >
                </div>
              </div>

              <hr class="my-4" />
            </card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import store from "../store";
export default {
  name: "user-profile",
  components: {
    flatPicker,
    VueTelInput,
  },
  data() {
    return {
      model: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        usertype: 0,
        gender: "male",
        student_id: "",
        showForStudent: false,
        mobile_number_valid: false,
        updateAccountLabel: "Update Profile",
        date_of_birth: "2018-07-17",
        roles: [],
        bindProps: {
          disabled: true,
          styleClasses: "form-group input-group-alternative",
          onlyCountries: ["KE", "RW", "TZ"],
          inputOptions: {
            styleClasses: "form-control ",
            showDialCode: false,
            placeholder: "Mobile Number",
            name: "mobile_number",
          },
        },
      },
      departments: [],
    };
  },

  computed: {
    ErrorResponse() {
      return this.$store.state.api.api_response;
    },
  },
  created() {
    this.model.id = store.state.api.user.id;
    this.model.role_id = store.state.api.user.role_id;
    this.model.first_name = store.state.api.user.firstName;
    this.model.middle_name = store.state.api.user.middleName;
    this.model.last_name = store.state.api.user.lastName;
    this.model.email = store.state.api.user.email;
    this.model.phone_number = store.state.api.user.phone_number;
    this.model.student_id = store.state.api.user.studentId;
    this.model.date_of_birth = store.state.api.user.dateOfBirth;
    this.model.gender = store.state.api.user.gender;

    if (store.state.api.user.role == "Student") {
      this.model.showForStudent = true;
      this.model.usertype = 1;
    }
  },
  methods: {
    checkMobileNumber(event) {
      if (event.valid) {
        this.model.mobile_number_valid = event.valid;
        this.model.phone_number = event.number;
      }
      if (this.model.mobile_number_valid) {
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-success";
      } else if (event.valid != undefined) {
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-danger";
        this.model.phone_number = "";
      }
    },
    updateUser: function (e) {
      e.preventDefault();
      this.model.isvalid = 1;
      if (!this.model.first_name) {
        this.model.first_name_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.middle_name) {
        this.model.middle_name_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.last_name) {
        this.model.last_name_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }

      if (!this.model.student_id && this.model.usertype == "1") {
        this.model.student_id_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }

      if (this.model.mobile_number_valid) {
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-success";
      } else {
        this.model.isvalid = this.model.isvalid * 0;
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-danger";
      }

      if (this.model.usertype == "2") {
        delete this.model.student_id;
        delete this.model.gender;
        delete this.model.date_of_birth;
      }

      if (this.model.isvalid) {
        this.model.hasError = false;
        this.model.updateAccountLabel = "Updating Profile ...";
        this.$store.dispatch("api/update_user", this.model).then(
          () => {
            this.model.updateAccountLabel = "Update Profile";
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Profile updated successfully",
            });
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            this.model.updateAccountLabel = "Update Profile";
            this.$swal({
              icon: "error",
              title: "Error",
              text: "Failed updating profile",
            });
          }
        );
      }
    },
  },
};
</script>
<style></style>
