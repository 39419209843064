<template>
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10">
      <div class="card bg-secondary shadow border-0">
        <form
          id="app"
          action="#"
          method="post"
          role="form"
          @submit="registerUser"
          novalidate="true"
        >
          <div class="card-header bg-transparent px-lg-5 py-lg-5">
            <base-alert v-if="model.hasError" type="danger" dismissible>
              <div v-for="(error, index) in ErrorResponse" :key="error">
                <span class="alert-inner--icon"
                  ><i class="fa fa-exclamation"></i
                ></span>

                <span class="alert-inner--text"
                  ><strong>{{ index }}</strong> {{ error }}</span
                >
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>

            <div class="text-muted text-center mt-2 mb-3">
              <small>Register as </small>
            </div>

            <div class="row">
              <div class="col-sm" v-for="role in model.roles" :key="role.id">
                <base-radio
                  name="usertype"
                  @change="onUserTypeChange($event)"
                  :checked="role.id === 1"
                  class="mb-3"
                  :value="role.id.toString()"
                  v-model="model.usertype"
                >
                  {{ role.name }}
                </base-radio>
              </div>
            </div>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <div class="row">
              <div class="col-sm">
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="First Name"
                  addon-left-icon="ni ni-circle-08"
                  v-model="model.first_name"
                  :valid="model.first_name_valid"
                  @blur="model.first_name_valid = undefined"
                >
                </base-input>
              </div>
              <div class="col-sm">
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="Middle Name"
                  addon-left-icon="ni ni-circle-08"
                  v-model="model.middle_name"
                  :valid="model.middle_name_valid"
                  @blur="model.middle_name_valid = undefined"
                >
                </base-input>
              </div>
              <div class="col-sm">
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="Last Name"
                  addon-left-icon="ni ni-circle-08"
                  v-model="model.last_name"
                  :valid="model.last_name_valid"
                  @blur="model.last_name_valid = undefined"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="Email"
                  addon-left-icon="ni ni-email-83"
                  v-model="model.email"
                  :valid="model.email_valid"
                  @blur="model.email_valid = undefined"
                >
                </base-input>
              </div>
              <div class="col-sm">
                <vue-tel-input
                  v-model="model.phone_number"
                  @validate="checkMobileNumber($event)"
                  v-bind="model.bindProps"
                ></vue-tel-input>
              </div>
            </div>
            <base-input
              formClasses="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
              :valid="model.password_valid"
              @blur="model.password_valid = undefined"
            >
            </base-input>

            <div v-if="model.showForStudent" id="student_only">
              <base-input
                formClasses="input-group-alternative"
                placeholder="Student ID"
                addon-left-icon="ni ni-hat-3"
                v-model="model.student_id"
                :valid="model.student_id_valid"
                @blur="model.student_id_valid = undefined"
              >
              </base-input>
              <div class="form-group input-group-alternative">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon3"
                      >Date of birth</span
                    >
                  </div>
                  <flat-picker
                    :config="{ allowInput: true }"
                    class="form-control datepicker"
                    v-model="model.date_of_birth"
                  >
                  </flat-picker>
                </div>
              </div>

              <div class="row">
                <div class="col-sm">
                  <base-radio
                    name="gender"
                    checked
                    class="mb-3"
                    value="male"
                    @input="genderSelect"
                    v-model="model.gender"
                  >
                    Male
                  </base-radio>
                </div>
                <div class="col-sm">
                  <base-radio
                    name="gender"
                    @input="genderSelect"
                    class="mb-3"
                    value="female"
                    v-model="model.gender"
                  >
                    Female
                  </base-radio>
                </div>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-12">
                <span class="text-muted"
                  >By clicking "Create Account", you are in agreement of the
                  <a href="#!">Terms of use </a> and
                  <a href="#!">Privacy Policy</a>.
                </span>
              </div>
            </div>
            <div class="text-center">
              <base-button nativeType="submit" type="primary" class="my-4">{{
                model.registerLabel
              }}</base-button>
            </div>
          </div>
        </form>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="/reset" class="text-light">
            <small>Forgot password?</small>
          </a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import RepoService from "../services/repository.service";
export default {
  name: "register",
  components: {
    flatPicker,
    VueTelInput,
  },
  data() {
    return {
      model: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        usertype: 1,
        gender: "male",
        student_id: "",
        showForStudent: true,
        mobile_number_valid: false,
        registerLabel: "Create Account",
        date_of_birth: "1980-01-01",
        roles: [],
        bindProps: {
          styleClasses: "form-group input-group-alternative",
          onlyCountries: ["KE", "RW", "TZ", "BW"],
          inputOptions: {
            styleClasses: "form-control ",
            showDialCode: false,
            placeholder: "Mobile Number",
            name: "mobile_number",
          },
        },
      },
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.api.status.isAuthenticated;
    },
    ErrorResponse() {
      return this.$store.state.api.api_response;
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push("/profile");
    }
  },
  mounted: function () {
    this.getRoles();
  },
  methods: {
    genderSelect(value) {
      this.model.gender = value;
    },
    getRoles() {
      var vm = this;
      RepoService.get_roles()
        .then(function (response) {
          vm.model.roles = response.data.data;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    checkMobileNumber(event) {
      if (event.valid) {
        this.model.mobile_number_valid = event.valid;
        this.model.phone_number = event.number;
      }
      if (this.model.mobile_number_valid) {
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-success";
      } else if (event.valid != undefined) {
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-danger";
        this.model.phone_number = "";
      }
    },
    onUserTypeChange(event) {
      this.model.usertype = event.target.value;
      if (event.target.value == 1) {
        this.model.showForStudent = true;
      } else {
        this.model.showForStudent = false;
      }
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    registerUser: function (e) {
      e.preventDefault();

      this.model.isvalid = 1;
      if (!this.model.first_name) {
        this.model.first_name_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.middle_name) {
        this.model.middle_name_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.last_name) {
        this.model.last_name_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }

      if (!this.model.email || !this.validEmail(this.model.email)) {
        this.model.email_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      } else {
        this.model.email_valid = true;
      }
      if (!this.model.student_id && this.model.usertype == "1") {
        this.model.student_id_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }

      if (!this.model.password) {
        this.model.password_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
        console.log(5);
      }
      if (this.model.mobile_number_valid) {
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-success";
      } else {
        this.model.isvalid = this.model.isvalid * 0;
        this.model.bindProps.styleClasses =
          "form-group input-group-alternative has-danger";
      }

      if (this.model.usertype != "1") {
        delete this.model.student_id;
        delete this.model.gender;
        delete this.model.date_of_birth;
      }

      if (this.model.isvalid) {
        this.$swal({
          icon: "question",
          title: "Confirm",
          allowOutsideClick: false,
          showCancelButton: true,
          html:
            "Please confirm:<br>  Email: " +
            this.model.email +
            "<br> Mobile No. " +
            this.model.phone_number +
            "<br> is correct then click on proceed button below",
          confirmButtonColor: "#2dce91",
          confirmButtonText: "Proceed",
          cancelButtonText: "Edit Details",
        }).then((response) => {
          if (response.isConfirmed) {
            this.model.hasError = false;
            this.model.registerLabel = "Creating Account ...";
            this.$store.dispatch("api/register", this.model).then(
              () => {
                this.$swal({
                  icon: "success",
                  title: "Success",
                  html:
                    '<span class="text-success"><i class="ni ni-check-bold"></i> Thank you ' +
                    this.model.first_name +
                    ", We have sent you an email to " +
                    this.model.email +
                    ". Please click the link in the email to activate your account.</span>",
                  confirmButtonColor: "#2dce91",
                  confirmButtonText: "Close",
                }).then(() => {
                  this.$router.push("/login");
                });
              },
              // eslint-disable-next-line no-unused-vars
              (error) => {
                this.model.registerLabel = "Create Account";
                this.model.hasError = true;
              }
            );
          }
        });
      }
    },
  },
};
</script>
<style>
.swal2-html-container {
  text-align: left !important;
  font-size: 16px !important;
}
</style>
