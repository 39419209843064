<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/profile-cover.jpeg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">
              Hello {{ model.display_name }},
            </h1>
            <p class="text-white mt-0 mb-5">
              Please use the form below to set a new password. Ensure you do not
              share your password with anyone.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <form
            id="app"
            action="#"
            method="post"
            role="form"
            @submit="changePassword"
            novalidate="true"
          >
            <card shadow type="secondary">
              <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">Change Password</h3>
                    </div>
                  </div>
                </div>
              </template>

              <div class="card-body px-lg-5 py-lg-5">
                <base-alert
                  v-if="model.hasResponse"
                  :type="AlertType"
                  dismissible
                >
                  <div v-for="(error, index) in ErrorResponse" :key="error">
                    <span class="alert-inner--icon"
                      ><i class="fa fa-exclamation"></i
                    ></span>
                    <span class="alert-inner--text ml-2"
                      ><strong>{{ index }}</strong> {{ error }}</span
                    >
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </base-alert>
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="Current Password"
                  addon-left-text="Current Password:"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open text-dark"
                  v-model="model.current_password"
                  :valid="model.current_password_valid"
                  @blur="model.current_password_valid = undefined"
                >
                </base-input>
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="New Password"
                  addon-left-text="New Password:"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open text-dark"
                  v-model="model.new_password"
                  :valid="model.new_password_valid"
                  @blur="model.new_password_valid = undefined"
                >
                </base-input>
                <base-input
                  formClasses="input-group-alternative"
                  placeholder="Confirm Password"
                  addon-left-text="Confirm Password:"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open text-dark"
                  v-model="model.confirm_password"
                  :valid="model.confirm_password_valid"
                  @blur="model.confirm_password_valid = undefined"
                >
                </base-input>
                <div class="text-center">
                  <base-button
                    nativeType="submit"
                    type="primary"
                    class="my-4"
                    >{{ model.updatePasswordLabel }}</base-button
                  >
                </div>
              </div>

              <hr class="my-4" />
            </card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../store";
export default {
  name: "user-profile",
  data() {
    return {
      model: {
        current_password: "",
        new_password: "",
        confirm_password: "",
        display_name: "",
        isvalid: 1,
        hasResponse: false,
        updatePasswordLabel: "Change Password",
      },
    };
  },
  computed: {
    ErrorResponse() {
      console.log(this.$store.state.api.api_response);
      return this.$store.state.api.api_response;
    },
    AlertType() {
      return this.$store.state.api.alert_type;
    },
  },
  created() {
    this.model.display_name = store.state.api.user.displayName;
  },
  methods: {
    changePassword: function (e) {
      e.preventDefault();
      this.model.isvalid = 1;
      if (!this.model.current_password) {
        this.model.current_password_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.new_password) {
        this.model.new_password_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (!this.model.confirm_password) {
        this.model.confirm_password_valid = false;
        this.model.isvalid = this.model.isvalid * 0;
      }
      if (this.model.isvalid == 1) {
        this.hasResponse = false;
        this.model.updatePasswordLabel = "Updating password ...";
        this.$store.dispatch("api/change_password", this.model).then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.model.hasResponse = true;
            this.model.updatePasswordLabel = "Change Password";
            this.model.current_password = null;
            this.model.new_password = null;
            this.model.confirm_password = null;
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            this.model.updatePasswordLabel = "Change Password";
            this.model.hasResponse = true;
          }
        );
      }
    },
  },
};
</script>
<style></style>
