<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} List
              </h3>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="uploads"
          >
            <template v-slot:columns>
              <th>Name</th>
              <th>View</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.name }}
              </th>
              <th scope="row">
                <a :href="row.item.path" target="_blank">
                  <i class="fa fa-search-plus mr-3"></i>
                  <span>View</span>
                </a>
              </th>
              <td class="text-right">
                <base-button
                  size="sm"
                  @click="delete_item(row.item)"
                  type="danger"
                  >Remove</base-button
                >
              </td>
            </template>
          </base-table>
        </div>

        <div
          class="card-footer d-flex justify-content-end"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <base-pagination total="30"></base-pagination>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} Form
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form
            id="app"
            action="#"
            method="post"
            role="form"
            @submit="submitData"
            novalidate="true"
          >
            <base-alert v-if="show_alert" :type="alert_type" dismissible>
              <div v-for="(row, index) in alert_response" :key="row">
                <span class="alert-inner--icon"
                  ><i class="fa fa-exclamation"></i
                ></span>

                <span class="alert-inner--text"
                  ><strong>{{ index }}</strong> {{ row }}</span
                >
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input form-control"
                v-bind:class="{ 'is-invalid': model.file_invalid }"
                id="customFileLang"
                lang="en"
                @change="handleFileUpload($event)"
              />
              <label class="custom-file-label" for="customFileLang">{{
                model.filename
              }}</label>
            </div>

            <div class="text-right">
              <base-button nativeType="submit" type="primary" class="my-4"
                >Upload</base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "uploads-table",
  show_alert: false,
  alert_type: "",
  file_invalid: false,
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      uploads: null,
      isvalid: 1,
      model: {
        id: null,
        name: "",
        file: "",
        filename: "",
      },
    };
  },
  mounted: function () {
    this.getUploads();
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  methods: {
    handleFileUpload(event) {
      this.model.file = event.target.files[0];
      this.model.filename = event.target.files[0].name;
      this.model.name = event.target.files[0].name;
    },
    delete_item: function (record) {
      this.model = record;
      this.deleteUpload();
    },
    submitData: function (e) {
      this.hasError = false;
      this.isvalid = 1;
      if (!this.model.name) {
        this.isvalid = this.isvalid * 0;
        this.model.name_valid = false;
      }
      if (!this.model.filename) {
        this.isvalid = this.isvalid * 0;
        this.model.file_invalid = true;
      }
      if (this.isvalid) {
        this.model.id != null ? this.updateUpload() : this.createUpload();
      }

      e.preventDefault();
    },
    getUploads() {
      this.$store.dispatch("api/get_uploads").then(
        (response) => {
          this.uploads = response.data.uploads;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    createUpload() {
      this.$store.dispatch("api/add_upload", this.model).then(
        (response) => {
          this.uploads.push(response.data);
          this.model.name = null;
          this.model.file = null;
          this.model.filename = null;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {}
      );
    },
    updateUpload() {
      this.$store.dispatch("api/update_upload", this.model).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {},
        // eslint-disable-next-line no-unused-vars
        (error) => {}
      );
    },
    deleteUpload() {
      this.$store.dispatch("api/del_uploads", this.model.id).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          var idx = this.uploads.indexOf(this.model);
          this.uploads.splice(idx, 1);
          this.model.id = null;
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Record deleted successfully",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          });
          console.log(error);
        }
      );
    },
  },
};
</script>
<style></style>
