<template>
  <modal
    v-if="is_admin"
    v-model:show="academic_year_modal"
    modal-classes="modal-dialog-centered"
    body-classes="p-0"
  >
    <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              Academic year
            </h3>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col- mb-3">
            Please indicate the academic year to update selected students to.
          </div>
        </div>
        <div class="row" v-for="year in years" :key="year">
          <div class="col">
            <base-radio
              name="score"
              class="mb-3"
              value="0"
              @input="yearSelect(year.id)"
              v-model="year_id"
            >
              {{ year.name }}
            </base-radio>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <base-button
          type="secondary"
          size="sm"
          @click="academic_year_modal = false"
          >Close</base-button
        >

        <base-button
          @click="submitRequest"
          :disabled="submitDisabled"
          type="success"
          size="sm"
          >Submit Request</base-button
        >
      </div>
    </div>
  </modal>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            v-if="is_admin"
            type="primary"
            @click="selectYear"
            size="sm"
            >Change Academic Year</base-button
          >
        </div>
      </div>
      <base-alert v-if="show_alert" :type="alert_type" dismissible>
        <div v-for="(row, index) in alert_response" :key="row">
          <span class="alert-inner--icon"
            ><i class="fa fa-exclamation"></i
          ></span>

          <span class="alert-inner--text"
            ><strong>{{ index }}</strong> {{ row }}</span
          >
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </base-alert>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="users"
      >
        <template v-slot:columns>
          <th><input type="checkbox" v-model="selectAll" /></th>
          <th>Name</th>
          <th>User Type</th>
          <th>Admin</th>
          <th>Global Reviewer</th>
          <th>Year</th>
          <th>Student ID</th>
          <th>Status</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <td v-if="user.id != row.item.id">
            <input
              type="checkbox"
              v-model="selected"
              :value="row.item.id"
              number
            />
          </td>
          <th scope="row" v-if="user.id != row.item.id">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Image placeholder" src="img/icons/user_icon.png" />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.displayName
                }}</span>
                <p class="text-sm">
                  {{ row.item.email }}<br />{{ row.item.phone_number }}
                </p>
              </div>
            </div>
          </th>
          <td v-if="user.id != row.item.id">
            {{ row.item.role }}
          </td>
          <td v-if="user.id != row.item.id">
            <i
              v-if="row.item.department[0].is_admin"
              class="ni ni-check-bold text-success"
            ></i
            ><i
              v-if="!row.item.department[0].is_admin"
              class="ni ni-fat-remove text-danger"
            ></i>
          </td>
          <td v-if="user.id != row.item.id">
            <i
              v-if="row.item.department[0].global_reviewer"
              class="ni ni-check-bold text-success"
            ></i
            ><i
              v-if="!row.item.department[0].global_reviewer"
              class="ni ni-fat-remove text-danger"
            ></i>
          </td>
          <td v-if="user.id != row.item.id">
            {{
              row.item.department[0].academic_year != null
                ? row.item.department[0].academic_year.name
                : ""
            }}
          </td>
          <td v-if="user.id != row.item.id">
            {{ row.item.studentId }}
          </td>

          <td v-if="user.id != row.item.id">
            <badge
              :type="
                row.item.department[0].is_approved
                  ? 'success'
                  : row.item.department[0].is_rejected
                  ? 'danger'
                  : 'info'
              "
            >
              <span class="status">{{
                row.item.department[0].is_approved
                  ? "Approved"
                  : row.item.department[0].is_rejected
                  ? "Rejected"
                  : "Pending"
              }}</span>
            </badge>

          </td>

          <td class="text-right" v-if="user.id != row.item.id">
            <div v-if="is_admin">
              <base-dropdown position="right">
                <template v-slot:title>
                  <a
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    data-boundary="viewport"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </a>
                </template>

                <button
                  v-if="!row.item.department[0].is_approved"
                  class="dropdown-item"
                  v-on:click="approve(row.item)"
                  href="javascript:void(0)"
                >
                  Approve
                </button>
                <button
                  v-if="row.item.department[0].is_approved"
                  class="dropdown-item"
                  v-on:click="disapprove(row.item)"
                  href="javascript:void(0)"
                >
                  Disapprove
                </button>
                <button
                  v-if="!row.item.department[0].is_rejected"
                  class="dropdown-item"
                  v-on:click="reject(row.item)"
                  href="javascript:void(0)"
                >
                  Reject
                </button>
                <div class="dropdown-divider"></div>
                <button
                  v-if="!row.item.department[0].is_admin"
                  class="dropdown-item"
                  v-on:click="makeAdmin(row.item)"
                  href="javascript:void(0)"
                >
                  Make Admin
                </button>

                <button
                  v-if="row.item.department[0].is_admin"
                  class="dropdown-item"
                  v-on:click="revokeAdmin(row.item)"
                  href="javascript:void(0)"
                >
                  Revoke Admin
                </button>

                <div class="dropdown-divider"></div>
                <button
                  v-if="!row.item.department[0].global_reviewer"
                  class="dropdown-item"
                  v-on:click="makeGlobalReviewer(row.item)"
                  href="javascript:void(0)"
                >
                  Make Global Reviewer
                </button>

                <button
                  v-if="row.item.department[0].global_reviewer"
                  class="dropdown-item"
                  v-on:click="revokeGlobalReviewer(row.item)"
                  href="javascript:void(0)"
                >
                  Revoke Global Reviewer
                </button>

                <div class="dropdown-divider"></div>
                <button
                  class="dropdown-item"
                  v-on:click="trash(row.item)"
                  href="javascript:void(0)"
                >
                  Trash
                </button>
              </base-dropdown>
            </div>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination total="30"></base-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "users-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      submitDisabled: "disabled",
      users: [],
      selected: [],
      academic_year_modal: false,
      years: [],
      year_id: 0,
    };
  },
  mounted: function () {
    this.getUsers();
  },

  computed: {
    selectAll: {
      get() {
        if (this.users && this.users.length > 0) {
          // A users array exists with at least one item
          let allChecked = true;

          for (const user of this.users) {
            if (!this.selected.includes(user.id)) {
              allChecked = false; // If even one is not included in array
            }

            // Break out of loop if mismatch already found
            if (!allChecked) break;
          }

          return allChecked;
        }

        return false;
      },
      set(value) {
        const checked = [];

        if (value) {
          this.users.forEach((user) => {
            checked.push(user.id);
          });
        }

        this.selected = checked;
      },
    },
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
    is_admin() {
      return this.$store.state.api.is_admin;
    },
    user() {
      return this.$store.state.api.user;
    },
  },
  methods: {
    submitRequest() {
      if (this.selected.length === 0) {
        this.academic_year_modal = false;
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Select atleast one student to be updated from the list ",
        });
      } else {
        var request = {
          user_ids: this.selected,
          year_id: this.year_id,
        };
        //request academic year
        this.$store.dispatch("api/update_academic_year", request).then(
          (response) => {
            this.years = response.data;
            this.academic_year_modal = false;
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Request successful for selected students.",
            });
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            console.log(error);
          }
        );
      }
    },
    yearSelect(year_id) {
      this.year_id = year_id;
      this.submitDisabled = false;
    },
    selectYear() {
      //request academic year
      this.$store
        .dispatch("api/get_years", this.$store.state.api.department_id)
        .then(
          (response) => {
            this.years = response.data;
            if (response.data.length === 0) {
              this.$swal({
                icon: "error",
                title: "Error",
                text:
                  "No academic years have been configured for this department. Kindly request department admin to add academic years.",
              });
            } else {
              this.academic_year_modal = true;
              this.submitDisabled = true;
            }
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            console.log(error);
          }
        );
    },
    approve(user) {
      this.$store.dispatch("api/approve_user", user.id).then(
        () => {
          user.department[0].is_approved = true;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    disapprove(user) {
      this.$store.dispatch("api/disapprove_user", user.id).then(
        () => {
          user.department[0].is_approved = false;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    reject(user) {
      this.$store.dispatch("api/reject_user", user.id).then(
        () => {
          user.department[0].is_rejected = true;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    makeAdmin(user) {
      this.$store.dispatch("api/make_admin", user.id).then(
        () => {
          user.department[0].is_admin = true;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    revokeAdmin(user) {
      this.$store.dispatch("api/revoke_admin", user.id).then(
        () => {
          user.department[0].is_admin = false;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    makeGlobalReviewer(user) {
      this.$store.dispatch("api/make_global_reviewer", user.id).then(
        () => {
          user.department[0].global_reviewer = true;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    revokeGlobalReviewer(user) {
      this.$store.dispatch("api/revoke_global_reviewer", user.id).then(
        () => {
          user.department[0].global_reviewer = false;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    trash(user) {
      this.$store.dispatch("api/delete_user", user.id).then(
        (response) => {
          var idx = this.users.indexOf(user);
          this.users.splice(idx, 1);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Record deleted successfully",
          });
          console.log(response.data);
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getUsers() {
      this.$store.dispatch("api/get_users").then(
        (response) => {
          this.users = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
<style></style>
