import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import VerifyLayout from "@/layout/VerifyLayout";
import TutorialLayout from "@/layout/TutorialLayout";

import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/UserProfile.vue";
import UserDepartment from "../views/UserDepartment.vue";
import Password from "../views/Password.vue";
import Login from "../views/Login.vue";
import Reset from "../views/Reset.vue";
import Register from "../views/Register.vue";
import Users from "../views/Users.vue";
import Departments from "../views/Departments.vue";
import Tools from "../views/Tools.vue";
import Years from "../views/Years.vue";
import Activities from "../views/Activities.vue";
import Cases from "../views/Cases.vue";
import Results from "../views/Results.vue";
import Downloads from "../views/Downloads.vue";
import Uploads from "../views/Uploads.vue";
import Tutorial from "../views/Tutorial.vue";
import Bills from "../views/Bills.vue";
import NewPassword from "../views/NewPassword.vue";

import Verify from "../views/Verify.vue";
import store from "../store";
import ManageCase from "../views/ManageCase.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
        meta: { requiresAuth: true },
      },
      {
        path: "/users",
        name: "users",
        components: { default: Users },
        meta: { requiresAuth: true },
      },
      {
        path: "/years",
        name: "years",
        components: { default: Years },
        meta: { requiresAuth: true },
      },
      {
        path: "/departments",
        name: "departments",
        components: { default: Departments },
        meta: { requiresAuth: true, isSuperAdmin: true },
      },
      {
        path: "/user-departments",
        name: "user-departments",
        components: { default: UserDepartment },
        meta: { requiresAuth: true },
      },
      {
        path: "/tools",
        name: "tools",
        components: { default: Tools },
        meta: { requiresAuth: true },
      },
      {
        path: "/activities",
        name: "activities",
        components: { default: Activities },
        meta: { requiresAuth: true },
      },
      {
        path: "/cases",
        name: "cases",
        components: { default: Cases },
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-case",
        name: "Manage Case",
        components: { default: ManageCase },
        meta: { requiresAuth: true },
      },
      {
        path: "/results",
        name: "results",
        components: { default: Results },
        meta: { requiresAuth: true },
      },
      {
        path: "/downloads",
        name: "downloads",
        components: { default: Downloads },
        meta: { requiresAuth: true },
      },
      {
        path: "/uploads",
        name: "uploads",
        components: { default: Uploads },
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
        meta: { requiresAuth: true },
      },
      {
        path: "/password",
        name: "change password",
        components: { default: Password },
        meta: { requiresAuth: true },
      },
      {
        path: "/bills",
        name: "Bills",
        components: { default: Bills },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/how-to",
        name: "tutorial",
        components: { default: Tutorial },
        meta: { guest: true },
      },
      {
        path: "/login",
        name: "login",
        components: { default: Login },
        meta: { guest: true },
      },
      {
        path: "/reset",
        name: "reset",
        components: { default: Reset },
        meta: { guest: true },
      },
      {
        path: "/new_password",
        name: "new_password",
        components: { default: NewPassword },
        meta: { guest: true },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
        meta: { guest: true },
      },
    ],
  },
  {
    path: "/",
    redirect: "how-to",
    component: TutorialLayout,
    children: [
      {
        path: "/how-to",
        name: "tutorial",
        components: { default: Tutorial },
        meta: { guest: true },
      },
    ],
  },
  {
    path: "/",
    redirect: "dashboard",
    component: VerifyLayout,
    children: [
      {
        path: "/verify",
        name: "verify",
        components: { default: Verify },
        meta: { guest: true },
      },
      {
        path: "/review_join_request",
        name: "review_join_request",
        components: { default: Verify },
        meta: { guest: true },
      },
      {
        path: "/manage_case",
        name: "manage_case",
        components: { default: Verify },
        meta: { guest: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      store.state.api.status.isAuthenticated &&
      store.state.api.access_token
    ) {
      if (to.matched.some((record) => record.meta.isSuperAdmin)) {
        if (store.state.api.user.role_id == 4) {
          next();
        } else {
          next("/dashboard");
        }
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
export default router;
