<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col-sm-12">
              <base-alert v-if="show_alert" :type="alert_type" dismissible>
                <div v-for="(row, index) in alert_response" :key="row">
                  <span class="alert-inner--icon"
                    ><i class="fa fa-exclamation"></i
                  ></span>

                  <span class="alert-inner--text"
                    ><strong>{{ index }}</strong> {{ row }}</span
                  >
                </div>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4">
              <el-select
                class="mb-3"
                style="width: 100%"
                placeholder="Select Year"
                v-model="model.academic_year_id"
                filterable
                :value="model.academic_year_id"
                @change="yearSelect"
              >
                <el-option
                  v-for="option in selects.years"
                  class="select-danger"
                  :value="option.id"
                  :label="option.name"
                  :key="option"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-md-4">
              <el-select
                class="mb-3"
                style="width: 100%"
                placeholder="Select tool"
                v-model="model.tool_id"
                filterable
                :value="model.tool_id"
                @change="toolSelect"
              >
                <el-option
                  v-for="option in selects.tools"
                  class="select-danger"
                  :value="option.id"
                  :label="option.name"
                  :key="option"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4 col-md-4 text-right">
              <base-button
                :disabled="isDisabled"
                @click="saveConfigs"
                type="primary"
                size="sm"
                >Save configurations</base-button
              >
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="tableData"
          >
            <template v-slot:columns>
              <th>Tool</th>
              <th>Activity</th>
              <th>Minimum No. of Cases</th>
              <th>Score Per Case</th>
              <th>Total Maximum Score</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.tool }}
              </th>
              <th v-html="row.item.name"></th>
              <td>
                <base-input
                  formClasses="input-group-alternative mb-3"
                  placeholder="0"
                  v-model="model.minimum_cases[row.index]"
                ></base-input>
              </td>
              <td class="text-right">
                <base-input
                  formClasses="input-group-alternative mb-3"
                  placeholder="0"
                  v-model="model.score_per_case[row.index]"
                >
                </base-input>
              </td>
              <td class="text-right">
                <base-input
                  formClasses="input-group-alternative mb-3"
                  placeholder="0"
                  v-model="model.total_max_score[row.index]"
                >
                </base-input>
              </td>
            </template>
          </base-table>
        </div>

        <div
          class="card-footer d-flex justify-content-end"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
export default {
  name: "cases-table",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
      isDisabled: true,
      model: {
        activities: [],
        minimum_cases: [],
        score_per_case: [],
        total_max_score: [],
        tool_id: null,
        academic_year_id: null,
        isvalid: 1,
      },
      selects: {
        simple: "",
        years: [],
        year_array: {},
        tools: [],
        tool_template_array: {},
      },
    };
  },
  mounted: function () {
    this.getTools();
    this.getYears();
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  methods: {
    toolSelect(val) {
      this.model.tool_id = val;
      this.getActivities(val);
      if (this.model.tool_id && this.model.academic_year_id) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    yearSelect(val) {
      this.model.academic_year_id = val;
      if (this.model.tool_id && this.model.academic_year_id) {
        this.getConfigs();
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    getYears() {
      this.$store.dispatch("api/get_years").then(
        (response) => {
          this.selects.years = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getTools() {
      this.$store.dispatch("api/get_tools").then(
        (response) => {
          this.selects.tools = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getActivities(val) {
      this.$store.dispatch("api/get_activities", val).then(
        (response) => {
          this.tableData = [];
          this.model.activities = [];
          this.model.minimum_cases = [];
          this.model.score_per_case = [];
          this.model.total_max_score = [];
          this.generateActivityList(response.data.data, "");
          this.getConfigs();
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    generateActivityList(activities, indent) {
      activities.forEach((activity) => {
        var _name = indent + activity.label;
        var _id = activity.value;
        var _tool_name = activity.tool.name;
        if (activity.children === undefined) {
          this.tableData.push({
            id: _id,
            name: _name,
            tool: _tool_name,
          });
        }
        if (activity.children !== undefined) {
          this.generateActivityList(
            activity.children,
            _name + '<span class="text-success"> ● </span> '
          );
        }
      });
    },
    saveConfigs() {
      this.$store.dispatch("api/save_case_configs", this.model).then(
        () => {},
        () => {}
      );
    },
    getConfigs() {
      this.tableData.forEach((item, index) => {
        this.model.activities[index] = item.id;
        this.model.minimum_cases[index] = 0;
        this.model.score_per_case[index] = 0;
        this.model.total_max_score[index] = 0;
      });
      this.$store.dispatch("api/get_case_configs", this.model).then(
        (response) => {
          this.tableData.forEach((item, index) => {
            this.model.activities[index] = item.id;

            if (
              response.data.minimumCases[this.model.academic_year_id] !=
              undefined
            ) {
              if (
                response.data.minimumCases[this.model.academic_year_id][
                  this.model.tool_id
                ] != undefined
              ) {
                if (
                  response.data.minimumCases[this.model.academic_year_id][
                    this.model.tool_id
                  ][item.id] != undefined
                ) {
                  this.model.minimum_cases[index] =
                    response.data.minimumCases[this.model.academic_year_id][
                      this.model.tool_id
                    ][item.id];
                }
              }
            }
            if (
              response.data.scorePerCase[this.model.academic_year_id] !=
              undefined
            ) {
              if (
                response.data.scorePerCase[this.model.academic_year_id][
                  this.model.tool_id
                ] != undefined
              ) {
                if (
                  response.data.scorePerCase[this.model.academic_year_id][
                    this.model.tool_id
                  ][item.id] != undefined
                ) {
                  this.model.score_per_case[index] =
                    response.data.scorePerCase[this.model.academic_year_id][
                      this.model.tool_id
                    ][item.id];
                }
              }
            }
            if (
              response.data.TotalMaxCase[this.model.academic_year_id] !=
              undefined
            ) {
              if (
                response.data.TotalMaxCase[this.model.academic_year_id][
                  this.model.tool_id
                ] != undefined
              ) {
                if (
                  response.data.TotalMaxCase[this.model.academic_year_id][
                    this.model.tool_id
                  ][item.id] != undefined
                ) {
                  this.model.total_max_score[index] =
                    response.data.TotalMaxCase[this.model.academic_year_id][
                      this.model.tool_id
                    ][item.id];
                }
              }
            }
          });
        },
        () => {}
      );
    },
  },
};
</script>
<style></style>
