<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/profile-cover.jpeg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Hello {{ model.first_name }},</h1>
            <p class="text-white mt-0 mb-5 text-wrap">
              Please choose a department you would like to join to be able to
              access content of that department. The Department Admin has to
              approve your request before its available for you to join.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <modal
        v-model:show="academic_year_modal"
        modal-classes="modal-dialog-centered"
        body-classes="p-0"
      >
        <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
          <div
            class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent' : ''"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Academic year
                </h3>
              </div>
            </div>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <div class="row">
              <div class="col mb-3">
                Please indicate your current academic year below.
              </div>
            </div>
            <div class="row" v-for="year in years" :key="year">
              <div class="col">
                <base-radio
                  name="score"
                  class="mb-3"
                  value="0"
                  @input="yearSelect(year.id)"
                  v-model="model.year_id"
                >
                  {{ year.name }}
                </base-radio>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <base-button
              type="secondary"
              size="sm"
              @click="academic_year_modal = false"
              >Close</base-button
            >

            <base-button @click="submitRequest" type="success" size="sm"
              >Submit Request</base-button
            >
          </div>
        </div>
      </modal>
      <div class="row justify-content-center">
        <div class="col-xl-8 order-xl-2 mb-5 mb-xl-0">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">My departments</h3>
                  </div>
                </div>
              </div>
            </template>
            <div class="list-group">
              <div
                v-for="department in departments"
                :key="department.id"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col">{{ department.name }}</div>

                  <div
                    class="col text-right text-success"
                    v-if="approved_departments.includes(department.id)"
                  >
                    ● <small>Joined</small>
                  </div>
                  <div v-if="!approved_departments.includes(department.id)">
                    <div class="col text-right">
                      <div v-if="rejected_departments.includes(department.id)">
                        <div class="col text-danger">
                          ● <small>Rejected</small>
                        </div>
                        <button
                          type="button"
                          @click="joinRequest(department.id)"
                          class="text-right btn btn-primary btn-sm"
                        >
                          Request to join again
                        </button>
                      </div>

                      <div
                        class="col text-info"
                        v-else-if="
                          pending_approval_departments.includes(department.id)
                        "
                      >
                        ● <small>Pending Approval</small>
                      </div>

                      <button
                        type="button"
                        @click="joinRequest(department.id)"
                        class="btn btn-primary btn-sm"
                        v-else
                      >
                        Request to join
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <a
                href="#"
                class="list-group-item list-group-item-action active disabled"
              >
                <div class="row align-items-center">
                  <div class="col">Dental Sciences</div>
                 <div class="col text-right text-success">
                    ● <small>Joined</small>
                  </div>
                </div></a
              >
             
              <a href="#" class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                  <div class="col">Pediatrics and Child Health</div>
                  <div class="col text-right">
                    <button type="button" class="btn btn-primary btn-sm">
                      Request to join
                    </button>
                  </div>
                </div></a
              > -->
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../store";
export default {
  name: "user-department",

  data() {
    return {
      academic_year_modal: false,
      years: [],
      error_message: "",
      model: {
        department_id: 0,
        year_id: 0,
        first_name: "",
      },
      departments: [],
      approved_departments: [],
      pending_approval_departments: [],
      rejected_departments: [],
    };
  },
  mounted: function () {
    this.getDepartments();
  },
  created() {
    this.model.first_name = store.state.api.user.firstName;
  },

  methods: {
    yearSelect(year_id) {
      this.model.year_id = year_id;
    },
    submitRequest() {
      this.academic_year_modal = false;
      this.$store.dispatch("api/dept_join_equest", this.model).then(
        (response) => {
          this.pending_approval_departments.push(this.model.department_id);
          this.$swal({
            icon: "success",
            title: "Success",
            text: response.message,
          });
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text:
              "Request was not successful.An error was encountered while making the request",
          });
          console.log(error);
        }
      );
    },
    joinRequest(department_id) {
      this.model.department_id = department_id;
      if (store.state.api.user.role_id == 1) {
        //request academic year
        this.$store.dispatch("api/get_years", this.model.department_id).then(
          (response) => {
            this.years = response.data;
            if (response.data.length === 0) {
              this.$swal({
                icon: "error",
                title: "Error",
                text:
                  "Selected department has no academic years to choose from. Kindly request department admin to add academic years.",
              });
            } else {
              this.academic_year_modal = true;
            }
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.submitRequest();
      }
    },
    getDepartments() {
      this.$store.dispatch("api/get_departments").then(
        (response) => {
          this.getMyDepartments(response.data);
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getMyDepartments(departments) {
      this.$store.dispatch("api/get_my_departments").then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          this.departments = departments;
          response.data.forEach((department) => {
            if (department.is_approved)
              this.approved_departments.push(department.id);
            else if (department.is_rejected)
              this.rejected_departments.push(department.id);
            else this.pending_approval_departments.push(department.id);
          });
          //this.departments = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
<style></style>
