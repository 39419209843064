<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} List
              </h3>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="downloads"
          >
            <template v-slot:columns>
              <th>File Name</th>
              <th>Download</th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.name }}
              </th>
              <td class="text-right text-blue">
                <a href="javascript:void(0)" @click="download_file(row.item)">
                  <i class="ni ni-cloud-download-95 mr-3"></i>
                  <span>Download</span>
                </a>
              </td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "downloads-table",
  props: {
    title: String,
  },
  data() {
    return {
      downloads: [],
    };
  },
  mounted: function () {
    this.get_files();
  },
  methods: {
    get_files() {
      var pdf = require.context("@/../public/downloads", true, /\.pdf$/);

      pdf.keys().forEach((key) =>
        this.downloads.push({
          name: key.replace("./", ""),
          extension: "pdf",
        })
      );
      var doc = require.context("@/../public/downloads", true, /\.doc$/);

      doc.keys().forEach((key) =>
        this.downloads.push({
          name: key.replace("./", ""),
          extension: "doc",
        })
      );
      var docx = require.context("@/../public/downloads", true, /\.docx$/);

      docx.keys().forEach((key) =>
        this.downloads.push({
          name: key.replace("./", ""),
          extension: "docx",
        })
      );
    },

    download_file(item) {
      axios({
        url: "/downloads/" + item.name,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.name + "." + item.extension);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
<style></style>
