import RepoService from "../services/repository.service";

const user = JSON.parse(localStorage.getItem("user"));
const access_token = localStorage.getItem("access_token");
const department_id = localStorage.getItem("department_id");
const academic_year_id = localStorage.getItem("academic_year_id");
var selected_department = localStorage.getItem("department_name");
var is_admin = JSON.parse(localStorage.getItem("is_admin"));
var global_admin = JSON.parse(localStorage.getItem("global_admin"));

if (selected_department == null) {
  selected_department = "Switch Department";
}
const initialState = access_token
  ? {
      status: { isAuthenticated: true, isLoading: false },
      access_token: access_token,
      department_id: department_id,
      academic_year_id: academic_year_id,
      user,
      is_admin,
      global_admin,
      selected_department: selected_department,
      alert_type: "",
      show_alert: false,
      api_response: [],
    }
  : {
      status: {
        isAuthenticated: false,
        isLoading: false,
      },
      user: null,
      is_admin: false,
      global_admin: false,
      selected_department: null,
      department_id: null,
      academic_year_id: null,
      show_alert: false,
      alert_type: "",
      api_response: [],
    };

export const api = {
  namespaced: true,
  state: initialState,
  actions: {
    switch_department({ commit }, department) {
      commit("switch_department", department);
    },
    get_results({ commit }, year_id) {
      commit("show_loader");
      return RepoService.get_results(year_id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    review_case({ commit }, req) {
      commit("show_loader");
      return RepoService.review_case(req).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    approve_case({ commit }, req) {
      commit("show_loader");
      return RepoService.approve_case(req).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_users({ commit }) {
      commit("show_loader");
      return RepoService.get_users().then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_supervisors({ commit }) {
      commit("show_loader");
      return RepoService.get_supervisors().then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_students({ commit }, year_id) {
      commit("show_loader");
      return RepoService.get_students(year_id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    // eslint-disable-next-line no-empty-pattern
    search_students({}, search) {
      return RepoService.search_students(search).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    // eslint-disable-next-line no-empty-pattern
    search_supervisors({}, search) {
      return RepoService.search_supervisors(search).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    update_academic_year({ commit }, request) {
      commit("show_loader");
      return RepoService.update_academic_year(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    approve_user({ commit }, id) {
      commit("show_loader");
      return RepoService.approve_user(id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    disapprove_user({ commit }, id) {
      commit("show_loader");
      return RepoService.disapprove_user(id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    reject_user({ commit }, id) {
      commit("show_loader");
      return RepoService.reject_user(id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    make_admin({ commit }, id) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.make_admin(id).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "User is now an admin",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    revoke_admin({ commit }, id) {
      commit("show_loader");
      return RepoService.revoke_admin(id).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "User is no longer an admin",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },

    make_global_admin({ commit }, id) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.make_global_admin(id).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "User is now global admin",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    revoke_global_admin({ commit }, id) {
      commit("show_loader");
      return RepoService.revoke_global_admin(id).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "User is no longer a global admin",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },

    delete_user({ commit }, user_id) {
      commit("show_loader");
      return RepoService.delete_user(user_id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    change_password({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.change_password(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Password changed successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    save_case_configs({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.save_case_configs(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Case configurations saved successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_case_configs({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.get_case_configs(request).then(
        (response) => {
          commit("hide_loader");

          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_activities({ commit }, tool_id) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.get_activities(tool_id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    add_activity({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.add_activity(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Activity created successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },

    update_activity({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");

      return RepoService.update_activity(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Activity updated successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    del_activity({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.delete_activity(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_cases({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.get_cases(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    add_case({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.add_case(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Case submitted successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_case({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.update_case(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Case updated successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    del_case({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.delete_case(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    reset_score({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.reset_score(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_years({ commit }, department_id) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.get_years(department_id).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    add_year({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.add_year(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Year created successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_year({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");

      return RepoService.update_year(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Year updated successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    del_years({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.delete_year(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          return Promise.reject(error);
        }
      );
    },
    dept_join_equest({ commit }, request) {
      commit("show_loader");
      return RepoService.dept_join_equest(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_departments({ commit }) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.get_departments().then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    get_my_departments({ commit }) {
      commit("show_loader");
      return RepoService.get_my_departments().then(
        (response) => {
          commit("hide_loader");
          var k = 0;
          response.data.data.forEach((department) => {
            if (
              department.is_approved &&
              k == 0 &&
              localStorage.getItem("department_id") == undefined
            ) {
              commit("switch_department", department);
              k++;
            }
          });

          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },

    add_department({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.add_department(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Department created successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_department({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");

      return RepoService.update_department(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Department updated successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    del_departments({ commit }, request) {
      commit("show_loader");

      return RepoService.delete_department(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          return Promise.reject(error);
        }
      );
    },
    get_uploads({ commit }) {
      commit("show_loader");
      return RepoService.get_uploads().then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    add_upload({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.add_upload(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Upload created successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_upload({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");

      return RepoService.update_upload(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Upload updated successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    del_uploads({ commit }, request) {
      commit("show_loader");

      return RepoService.delete_upload(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },

    get_tools({ commit }) {
      commit("show_loader");
      return RepoService.get_tools().then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    // eslint-disable-next-line no-empty-pattern
    pay_bill({ commit }, request) {
      commit("show_loader");
      return RepoService.pay_bill(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          return Promise.reject(error);
        }
      );
    },
    query_transaction_status({ commit }, request) {
      commit("show_loader");
      return RepoService.query_transaction_status(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          return Promise.reject(error);
        }
      );
    },
    get_bills({ commit }) {
      commit("show_loader");
      return RepoService.get_bills().then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },

    // eslint-disable-next-line no-empty-pattern
    get_stats({}) {
      return RepoService.get_stats().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    // eslint-disable-next-line no-empty-pattern
    activity_score_summary({}, activity_id) {
      return RepoService.activity_score_summary(activity_id).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    add_tool({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");
      return RepoService.add_tool(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Tool created successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_tool({ commit }, request) {
      commit("show_loader");
      commit("api_response_reset");

      return RepoService.update_tool(request).then(
        (response) => {
          commit("hide_loader");
          commit("api_success", {
            Success: "Tool updated successfully",
          });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    del_tools({ commit }, request) {
      commit("show_loader");

      return RepoService.delete_tool(request).then(
        (response) => {
          commit("hide_loader");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    login({ commit }, loginObject) {
      return RepoService.login(loginObject).then(
        (response) => {
          console.log(response);
          if (response.data.access_token) {
            commit("loginSuccess", response.data);
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          commit("loginFailure", error);
          return Promise.reject(error);
        }
      );
    },
    reset({ commit }, resetObject) {
      commit("api_response_reset");
      return RepoService.reset(resetObject).then(
        (response) => {
          if (response.data) {
            commit("api_success", response.data);
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_password({ commit }, passwordObject) {
      commit("api_response_reset");
      return RepoService.update_password(passwordObject).then(
        (response) => {
          if (response.data) {
            commit("api_success", response.data);
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    verify({ commit }, token) {
      commit("logout");
      return RepoService.verify(token).then(
        (response) => {
          if (response.data.data.access_token) {
            commit("loginSuccess", response.data.data);
            commit("switch_department", response.data.data.department);
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          commit("loginFailure", error);
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit("show_loader");
      return RepoService.logout().then(
        (response) => {
          commit("logout");
          return Promise.resolve(response.data);
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          commit("logout");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    register({ commit }, user) {
      return RepoService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
    update_user({ commit }, user) {
      return RepoService.update_user(user).then(
        (response) => {
          commit("hide_loader");
          commit("update_user_success", response.data.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("hide_loader");
          commit("api_failed", error);
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    switch_department(state, department) {
      state.department_id = department.id;
      state.selected_department = department.name;
      state.is_admin = department.is_admin;
      state.global_admin = department.global_admin;

      if (department.academic_year != undefined) {
        state.academic_year_id = department.academic_year.id;
        localStorage.setItem("academic_year_id", department.academic_year.id);
      }

      localStorage.setItem("department_id", department.id);
      localStorage.setItem("department_name", department.name);
      localStorage.setItem("is_admin", department.is_admin);
      localStorage.setItem("global_admin", department.global_admin);
    },
    loginSuccess(state, response) {
      state.status.isAuthenticated = true;
      state.user = response.user;
      state.access_token = response.access_token;
      localStorage.setItem("user", JSON.stringify(response.user));
      localStorage.setItem("access_token", response.access_token);
    },
    update_user_success(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    loginFailure(state, error) {
      state.status.isAuthenticated = false;
      state.user = null;
      try {
        if (error.response.status == 401) {
          state.api_response = {
            "Error ": error.response.data.message,
          };
        }
        if (error.response.status == 400) {
          state.api_response = {
            "Error ": error.response.data.message,
          };
        }
        if (error.response.status == 402) {
          state.api_response = {
            "Error ": error.response.data.message,
          };
        }
        if (error.response.status == 405) {
          state.api_response = {
            "Server Error": "Method not allowed",
          };
        }
        if (error.response.status == 504 || error.response.status == 500) {
          state.api_response = {
            "Server Error": "Internal server Error. Please contact admin",
          };
        }
      } catch {
        state.api_response = {
          "Server Error": "Internal server Error. Please contact admin",
        };
      }
    },
    logout(state) {
      localStorage.clear();
      state.status.isAuthenticated = false;
      state.status.isLoading = false;
      state.user = null;
      state.access_token = null;
      state.department_id = null;
      state.selected_department = null;
    },
    registerSuccess(state) {
      state.status.isAuthenticated = false;
      state.user = null;
    },
    registerFailure(state, err) {
      state.status.isAuthenticated = false;
      state.api_response = err;
    },
    show_loader(state) {
      state.status.isLoading = true;
    },
    hide_loader(state) {
      state.status.isLoading = false;
    },
    api_failed(state, error) {
      let err = "";
      let message = "";
      try {
        if (error.response.status == 404) {
          state.api_response = {
            "Server Error": error.response.data.message,
          };
        }
        if (error.response.status == 401) {
          state.api_response = {
            "Server Error": "Action not authorized",
          };
        }

        if (error.response.status == 422) {
          if (error.response.data.message != undefined) {
            message = error.response.data.message;
          }
          if (error.response.data.errors) {
            err = {
              Error:
                message +
                " " +
                JSON.stringify(error.response.data.errors)
                  .replaceAll('"', "")
                  .replaceAll("{", "")
                  .replaceAll("}", "")
                  .replaceAll("[", "")
                  .replaceAll("]", ""),
            };
          } else {
            err = {
              Error: message,
            };
          }

          state.api_response = err;
        }
        if (error.response.status == 403) {
          state.api_response = {
            "Server Error": "You are not authorized to perform this action",
          };
        }
        if (error.response.status == 405) {
          state.api_response = {
            "Server Error": "Method not allowed",
          };
        }
        if (error.response.status == 504 || error.response.status == 500) {
          state.api_response = {
            "Server Error": "Internal server Error. Please contact admin",
          };
        }
        if (error.response.status == 402) {
          state.api_response = {
            "Error ": error.response.data.message,
          };
        }
      } catch (err) {
        console.log(err);
        state.api_response = {
          "Server Error": "Internal server Error. Please contact admin",
        };
      }

      state.show_alert = true;
      state.alert_type = "danger";
    },
    api_success(state, res) {
      state.api_response = res;
      state.show_alert = true;
      state.alert_type = "success";
    },
    api_response_reset(state) {
      state.api_response = [];
      state.show_alert = false;
      state.alert_type = "";
    },
  },
};
