import _axios from "../services/axios";
import store from "../store";
class RepositoryService {
  login(user) {
    return _axios.post("oauth/token", {
      grant_type: "password",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      username: user.email,
      password: user.password,
    });
  }
  reset(user) {
    return _axios.post("forgot-password", {
      email: user.email,
    });
  }
  update_password(req) {
    return _axios.post("reset-password", {
      email: req.email,
      token: req.token,
      password: req.password,
      password_confirmation: req.confirm_password,
    });
  }
  verify(token) {
    return _axios.post("verify", {
      email_token: token,
      passport_client_id: process.env.VUE_APP_CLIENT_ID,
      passport_client_secret: process.env.VUE_APP_CLIENT_SECRET,
    });
  }

  logout() {
    return _axios.post("logout");
  }

  register(user) {
    return _axios.post("users/create", {
      role_id: user.usertype,
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      student_id: user.student_id,
      gender: user.gender,
      date_of_birth: user.date_of_birth,
      phone_number: user.phone_number,
      email: user.email,
      password: user.password,
    });
  }
  update_user(user) {
    return _axios.patch("users/update/" + user.id, {
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      student_id: user.student_id,
      gender: user.gender,
      date_of_birth: user.date_of_birth,
      phone_number: user.phone_number,
      email: user.email,
      role_id: user.role_id,
    });
  }
  get_roles() {
    return _axios.get("roles");
  }

  get_users() {
    return _axios.get("users");
  }
  get_supervisors() {
    return _axios.get("users?is_supervisor=true&is_approved");
  }
  get_students(year_id) {
    return _axios.get("users?is_student=true&academic_year_id=" + year_id);
  }
  search_students(search) {
    return _axios.get(
      "users?search=" +
        search +
        "&searchFields=first_name;middle_name;last_name;email;phone_number;student_id&condition=like&is_student=true"
    );
  }
  search_supervisors(search) {
    return _axios.get(
      "users?search=" +
        search +
        "&searchFields=first_name;middle_name;last_name;email;phone_number;student_id&condition=like&is_supervisor=true"
    );
  }

  update_academic_year(request) {
    return _axios.post("departments/update/academic_year", {
      user_ids: request.user_ids,
      academic_year_id: request.year_id,
    });
  }
  approve_user(id) {
    return _axios.post("departments/join/request/approve", {
      user_id: id,
    });
  }
  disapprove_user(id) {
    return _axios.post("departments/join/request/disapprove", {
      user_id: id,
    });
  }
  reject_user(id) {
    return _axios.post("departments/join/request/reject", {
      user_id: id,
    });
  }
  make_admin(id) {
    return _axios.post("department/user/make-admin", {
      department_id: store.state.api.department_id,
      user_id: id,
      key: process.env.VUE_APP_KEY,
    });
  }
  revoke_admin(id) {
    return _axios.post("department/user/revoke-admin", {
      department_id: store.state.api.department_id,
      user_id: id,
      key: process.env.VUE_APP_KEY,
    });
  }
  make_global_admin(id) {
    return _axios.post("departments/global/admin/approve", {
      user_id: id,
    });
  }
  revoke_global_admin(id) {
    return _axios.post("departments/global/admin/reject", {
      user_id: id,
    });
  }
  delete_user(user_id) {
    return _axios.delete("departments/join/request/delete?user_id=" + user_id);
  }

  change_password(request) {
    return _axios.post("user/change-password", {
      old_password: request.current_password,
      password: request.new_password,
      password_confirmation: request.confirm_password,
    });
  }
  dept_join_equest(request) {
    return _axios.post("departments/join/request", {
      department_id: request.department_id,
      academic_year_id: request.year_id,
    });
  }
  get_my_departments() {
    return _axios.get("user/departments");
  }
  get_departments() {
    return _axios.get("departments");
  }
  add_department(request) {
    return _axios.post("departments/create", {
      name: request.name,
      description: request.description,
    });
  }
  update_department(request) {
    return _axios.patch("departments/update/" + request.id, {
      name: request.name,
      description: request.description,
    });
  }
  delete_department(id) {
    return _axios.delete("departments/delete/" + id);
  }
  get_activities(tool_id) {
    if (tool_id === undefined) return _axios.get("activities");
    else return _axios.get("activities?tool_id=" + tool_id);
  }
  add_activity(request) {
    return _axios.post("activities/create", {
      name: request.name,
      template: request.template,
      parent_id: request.activity.id,
      tool_id: request.tool.id,
    });
  }
  update_activity(request) {
    return _axios.patch("activities/update/" + request.id, {
      name: request.name,
      template: request.template,
      parent_id: request.activity.id,
      tool_id: request.tool.id,
    });
  }
  delete_activity(id) {
    return _axios.delete("activities/delete/" + id);
  }
  get_bills() {
    return _axios.get("invoices?per_page=500");
  }
  pay_bill(request) {
    return _axios.post("initiate-stk/" + request.id, {
      phone_number: request.phone_number,
    });
  }
  query_transaction_status(request) {
    return _axios.post("query_transaction_status/", {
      confirmation_code: request.confirmation_code,
    });
  }

  get_stats() {
    return _axios.get("dashboard");
  }
  activity_score_summary(activity_id) {
    return _axios.get("cases/results/activity/score/summary/" + activity_id);
  }
  get_tools() {
    return _axios.get("tools");
  }
  add_tool(request) {
    return _axios.post("tools/create", {
      name: request.name,
    });
  }
  update_tool(request) {
    return _axios.patch("tools/update/" + request.id, {
      name: request.name,
    });
  }
  delete_tool(id) {
    return _axios.delete("tools/delete/" + id);
  }
  get_cases(request) {
    var query = "";
    if (request.filter != null) {
      if (
        request.filter.student.id != "" &&
        request.filter.student.id != null
      ) {
        query += "&student_id=" + request.filter.student.id;
      }
      if (
        request.filter.supervisor.id != "" &&
        request.filter.supervisor.id != null
      ) {
        query += "&supervisor_id=" + request.filter.supervisor.id;
      }
      if (request.filter.tool.id != "" && request.filter.tool.id != null) {
        query += "&tool_id=" + request.filter.tool.id;
      }
      if (
        request.filter.academic_year.id != "" &&
        request.filter.academic_year.id != null
      ) {
        query += "&academic_year_id=" + request.filter.academic_year.id;
      }
      if (
        request.filter.activity.id != "" &&
        request.filter.activity.id != null
      ) {
        query += "&activity_id=" + request.filter.activity.id;
      }
      if (request.filter.status.id != "" && request.filter.status.id != null) {
        query += "&status=" + request.filter.status.id;
      }
      if (request.filter.case_no != "") {
        query += "&case_number=" + request.filter.case_no;
      }
    }
    return _axios.get(
      "cases/results?page=" + request.pagination.current + query
    );
  }
  add_case(formData) {
    return _axios.post("cases/results/create", formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
  update_case(formData) {
    return _axios.post("cases/results/update/" + formData.get("id"), formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
  delete_case(request) {
    return _axios.post("cases/results/delete/" + request.id, {
      reason: request.reason,
    });
  }
  reset_score(request) {
    return _axios.post("cases/results/reset_score/" + request.id, {
      reason: request.reason,
    });
  }
  review_case(request) {
    var id = request.id;
    delete request.id;
    return _axios.patch("cases/results/review/" + id, request);
  }
  approve_case(request) {
    var id = request.id;
    delete request.id;
    return _axios.patch("cases/results/approve/" + id);
  }

  get_years(department_id) {
    if (department_id == undefined) return _axios.get("academic-years");
    else return _axios.get("academic-years?department_id=" + department_id);
  }
  add_year(request) {
    return _axios.post("academic-years/create", {
      name: request.name,
    });
  }
  update_year(request) {
    return _axios.patch("academic-years/update/" + request.id, {
      name: request.name,
    });
  }
  delete_year(id) {
    return _axios.delete("academic-years/delete/" + id);
  }
  save_case_configs(request) {
    return _axios.post("cases/configurations/create", request);
  }
  get_case_configs(request) {
    return _axios.get(
      "cases/configurations?academic_year_id=" +
        request.academic_year_id +
        "&tool_id=" +
        request.tool_id
    );
  }
  get_results(year_id) {
    return _axios.get("cases/results/report?academic_year_id=" + year_id);
  }

  get_uploads() {
    return _axios.get("uploads");
  }
  add_upload(request) {
    let formData = new FormData();
    formData.append("document", request.file);
    formData.append("name", request.name);

    return _axios.post("uploads/create", formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
  update_upload(request) {
    let formData = new FormData();
    formData.append("document", request.file);
    formData.append("name", request.name);
    return _axios.patch("uploads/update/" + request.id, formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
  delete_upload(id) {
    return _axios.delete("uploads/delete/" + id);
  }
  downloadResults(year_id) {
    return _axios.get("cases/results/download?academic_year_id=" + year_id, {
      responseType: "blob",
    });
  }
  downloadResultPdf(student_id, year_id) {
    return _axios.get(
      "cases/results/download/result/pdf?student_id=" +
        student_id +
        "&academic_year_id=" +
        year_id,
      {
        responseType: "blob",
      }
    );
  }
  downloadResultExcel(student_id, year_id) {
    return _axios.get(
      "cases/results/download/result/excel?student_id=" +
        student_id +
        "&academic_year_id=" +
        year_id,
      {
        responseType: "blob",
      }
    );
  }
}

export default new RepositoryService();
