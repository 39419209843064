import axios from "axios";
import store from "../store";

const axios_instance = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT + process.env.VUE_APP_API_VERSION,
});
axios_instance.interceptors.request.use(
  (config) => {
    let token = store.state.api.access_token;
    let department_id = store.state.api.department_id;

    let headers = {
      authorization: "Bearer  " + token,
      "department-id": department_id,
      accept: "application/json",
    };
    config.headers = headers;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios_instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => Promise.reject(error)
);

export default axios_instance;
