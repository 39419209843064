<template>
  <div class="row">
    <div class="col-xl-8 order-xl-2 mb-5 mb-xl-0">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-left">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} List
              </h3>
            </div>
          </div>
        </div>

        <!--Form modal-->
        <modal v-model:show="show_payment_confirm" size="sm" body-classes="p-0">
          <card
            type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0"
          >
            <template v-slot:header>
              <div class="text-danger text-center mt-2 mb-3 text-wrap">
                <small
                  >The number below will be used to complete the payment via
                  Mpesa; You can edit the number to the one you prefer for
                  completing the transaction.
                </small>
              </div>

              <base-input
                alternative
                v-model="model.phone_number"
                formClasses="input-group-alternative mb-3"
                placeholder="Email"
                addon-left-icon="fa fa-phone"
              >
              </base-input>

              <div class="text-center">
                <base-button @click="pay_item()" type="danger" class="my-4"
                  >Proceed to Pay</base-button
                >
              </div>
            </template>
          </card>
        </modal>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="bills"
          >
            <template v-slot:columns>
              <th>Inv. No.</th>
              <th>Period</th>
              <th>Amount(KES)</th>
              <th>Account</th>
              <th>Status</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.invoice_no }}
              </th>
              <td scope="row">
                {{ row.item.period }}
              </td>
              <td scope="row">
                {{ row.item.amount }}
              </td>
              <td scope="row">
                {{ row.item.user.first_name + " " + row.item.user.last_name }}
                <br />
                <small>{{ row.item.user.phone_number }}</small>
              </td>
              <td scope="row">
                <badge :type="row.item.is_paid ? 'success' : 'danger'">
                  <span class="status">{{
                    row.item.is_paid ? "Paid" : "Pending"
                  }}</span>
                </badge>
              </td>
              <td class="text-right">
                <base-button
                  v-show="!row.item.is_paid"
                  size="sm"
                  @click="pay_item_confirm(row.item)"
                  type="danger"
                  >Pay now</base-button
                >
              </td>
            </template>
          </base-table>
        </div>

        <div
          class="card-footer d-flex justify-content-end"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <base-pagination total="30"></base-pagination>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-success text-left mt-2 mb-3 text-wrap">
              <small
                >Kindly enter the Mpesa Payment Confirmation Code to verify
                payment.
              </small>
            </div>
          </template>

          <base-input
            alternative
            v-model="model.confirmation_code"
            formClasses="input-group-alternative mb-3"
            placeholder="QC16Y8989I"
            addon-left-icon="fa fa-bars"
          >
          </base-input>

          <div class="text-center">
            <base-button
              @click="confirm_transaction()"
              type="success"
              class="my-4"
              >Confirm Payment</base-button
            >
          </div>
          <template v-slot:footer>
            <div class="text-info text-left mt-2 mb-3 text-wrap">
              <b>Paybill : 4061629</b>
              <br>
              <b>Account Number : Invoice No.</b>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bills-table",
  show_alert: false,
  alert_type: "",
  file_invalid: false,
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      bills: [],
      isvalid: 1,
      show_payment_confirm: false,
      model: {
        id: null,
        phone_number: null,
        confirmation_code: null,
        invoice_number: "",
        period: "",
        amount: 0,
        status: false,
      },
    };
  },
  mounted: function () {
    this.getBills();
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    user() {
      return this.$store.state.api.user;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  methods: {
    pay_item_confirm: function (record) {
      this.model = record;
      this.model.phone_number = this.user.phone_number;
      this.show_payment_confirm = true;
    },
    pay_item: function () {
      this.paybill();
    },

    getBills() {
      this.$store.dispatch("api/get_bills").then(
        (response) => {
          this.bills = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },

    paybill() {
      this.show_payment_confirm = false;

      this.$store.dispatch("api/pay_bill", this.model).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          this.$swal({
            icon: "success",
            title: "Mpesa Payment Prompt",
            text:
              "Please wait for an Mpesa PIN prompt on your mobile phone to enable you complete the payment!",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          });
          console.log(error);
        }
      );
    },
    confirm_transaction() {
      this.show_payment_confirm = false;

      this.$store.dispatch("api/query_transaction_status", this.model).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          this.$swal({
            icon: "success",
            title: "Payment confirmed!",
            text: "Payment has been confirmed Successfully",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          });
          console.log(error);
        }
      );
    },
  },
};
</script>
<style></style>
