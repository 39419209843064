<template>
  <div
    class="wrapper"
    :class="{ 'nav-open': $sidebar.showSidebar }"
    v-if="user != null"
  >
    <loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="#ff9500"
      loader="dots"
    ></loading>
    <side-bar
      :background-color="sidebarBackground"
      short-title="Lexma"
      title="Lexma Dashboard"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          v-if="(user.role_id === 2 && is_admin) || user.role_id === 4"
          :link="{
            name: 'Users',
            icon: 'ni ni-circle-08 text-blue',
            path: '/users',
          }"
        />
        <sidebar-item
          v-if="(user.role_id === 2 && is_admin) || user.role_id === 4"
          :link="{
            name: 'Academic Years',
            icon: 'ni ni-calendar-grid-58 text-blue',
            path: '/years',
          }"
        />
        <sidebar-item
          v-if="user.role_id === 4"
          :link="{
            name: 'Departments',
            icon: 'ni ni-building text-blue',
            path: '/departments',
          }"
        />
        <sidebar-item
          v-if="(user.role_id === 2 && is_admin) || user.role_id === 4"
          :link="{
            name: 'Tools',
            icon: 'ni ni-collection text-blue',
            path: '/tools',
          }"
        />
        <sidebar-item
          v-if="(user.role_id === 2 && is_admin) || user.role_id === 4"
          :link="{
            name: 'Activities',
            icon: 'ni ni-bullet-list-67 text-blue',
            path: '/activities',
          }"
        />
        <sidebar-item
          v-if="(user.role_id === 2 && is_admin) || user.role_id === 4"
          :link="{
            name: 'Cases Configuration',
            icon: 'ni ni-folder-17 text-blue',
            path: '/cases',
          }"
        />
        <sidebar-item
          :link="{
            name: user.role === 'Student' ? 'Submit Case' : 'Submitted Cases',
            icon: 'ni ni-collection text-blue',
            path: '/manage-case',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Results',
            icon: 'ni ni-collection text-blue',
            path: '/results',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Downloads',
            icon: 'ni ni-cloud-download-95 text-blue',
            path: '/downloads',
          }"
        />
        <sidebar-item
          v-if="
            (user.role_id === 2 && is_admin) ||
            user.role === 'Student' ||
            user.role_id === 4
          "
          :link="{
            name: 'Uploads',
            icon: 'ni ni-cloud-upload-96 text-blue',
            path: '/uploads',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    Loading,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      fullPage: true,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.api.status.isAuthenticated;
    },
    ErrorResponse() {
      return this.$store.state.api.error_response;
    },
    isLoading() {
      return this.$store.state.api.status.isLoading;
    },
    user() {
      return this.$store.state.api.user;
    },
    is_admin() {
      return this.$store.state.api.is_admin;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
.table-responsive {
  overflow: auto;
  height: 500px;
}
.table-responsive thead  {
  position: sticky;
  top: 0;
  z-index: 2;
}
.dropdown-menu {
  
    top: -10px !important;
    //left: -200px !important;
}
</style>
