<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-sm-12">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    {{ user.role == "Student" ? "My cases" : "Review cases" }}
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button
                    v-if="user.role == 'Student'"
                    type="primary"
                    @click="addnew"
                    size="sm"
                    >Add New</base-button
                  >

                  <el-popover
                    placement="bottom-start"
                    :width="1000"
                    :visible="filter_visible"
                    trigger="click"
                  >
                    <div class="popover-header">Filter cases</div>
                    <div class="row">
                      <div class="col-sm-3 col-md-3">
                        <base-input
                          label="Case No."
                          v-model="filter.case_no"
                          placeholder="18219AD28553"
                        />
                      </div>
                      <div class="col-sm-3 col-md-3">
                        <label for="select_student" class="form-control-label"
                          >Student</label
                        >
                        <el-select
                          placeholder="Search Student"
                          v-model="filter.student.id"
                          remote
                          clearable
                          filterable
                          :remote-method="filterStudents"
                          :loading="loading"
                          @change="studentSelect"
                        >
                          <el-option
                            v-for="student in studentslist"
                            class="select-danger"
                            :value="student.id"
                            :label="student.displayName"
                            :key="student.name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-sm-3 col-md-3">
                        <label for="select_student" class="form-control-label"
                          >Supervisor</label
                        >
                        <el-select
                          placeholder="Search Supervisor"
                          v-model="filter.supervisor.id"
                          remote
                          clearable
                          filterable
                          :remote-method="filterSupervisors"
                          :loading="loading"
                          @change="searchSupervisorSelect"
                        >
                          <el-option
                            v-for="supervisor in supervisorslist"
                            class="select-danger"
                            :value="supervisor.id"
                            :label="supervisor.displayName"
                            :key="supervisor.name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-sm-3 col-md-3">
                        <label for="select_status" class="form-control-label"
                          >Case Status</label
                        >
                        <el-select
                          placeholder="Select Status"
                          v-model="filter.status.id"
                          clearable
                          filterable
                          @change="reviewStatusSelect"
                        >
                          <el-option
                            v-for="state in states"
                            class="select-danger"
                            :value="state.id"
                            :label="state.name"
                            :key="state.name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <el-select
                          class="mb-3"
                          style="width: 100%"
                          placeholder="Select Year"
                          v-model="filter.academic_year.id"
                          :filterable="model.filterable"
                          :value="filter.academic_year.id"
                          @change="academicYearSelectFilter"
                        >
                          <el-option
                            v-for="option in selects.academic_years"
                            class="select-danger"
                            :value="option.id"
                            :label="option.name"
                            :key="option"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-4">
                        <el-select
                          class="mb-3"
                          style="width: 100%"
                          placeholder="Select tool"
                          v-model="filter.tool.id"
                          :filterable="model.filterable"
                          :value="filter.tool.id"
                          clearable
                          @change="toolSelectFilter"
                        >
                          <el-option
                            v-for="option in selects.tools"
                            class="select-danger"
                            :value="option.id"
                            :label="option.name"
                            :key="option"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-4">
                        <el-cascader
                          style="width: 100%"
                          :disabled="selects.disabled"
                          placeholder="Select sub-speciality"
                          v-model="filter.activity.id"
                          :options="selects.activities"
                          @change="setActivityFilter"
                          :filterable="model.filterable"
                          clearable
                          :props="{ checkStrictly: true }"
                        >
                        </el-cascader>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <el-button
                          size="small"
                          type="success"
                          style="width: 100%"
                          @click="filterCases"
                          >Filter</el-button
                        >
                      </div>
                      <div class="col-sm-6">
                        <el-button
                          size="small"
                          type="danger"
                          style="width: 100%"
                          @click="filter_visible = false"
                          >cancel</el-button
                        >
                      </div>
                    </div>
                    <template #reference>
                      <el-button
                        type="primary"
                        @click="filter_visible = !filter_visible"
                        size="small"
                        >Filter Cases</el-button
                      >
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
            <modal
              v-model:show="caseform"
              modal-classes="modal-dialog-centered modal-xl"
              body-classes="p-0"
            >
              <div
                class="card shadow"
                :class="type === 'dark' ? 'bg-default' : ''"
              >
                <div
                  class="card-header border-0"
                  :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h3
                        class="mb-0"
                        :class="type === 'dark' ? 'text-white' : ''"
                      >
                        Case Form
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="card-body px-lg-5 py-lg-5">
                  <form
                    id="app"
                    action="#"
                    method="post"
                    role="form"
                    @submit="submitData"
                    novalidate="true"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <el-select
                              class="mb-3"
                              style="width: 100%"
                              placeholder="Select tool"
                              v-model="model.tool.id"
                              :filterable="model.filterable"
                              :value="model.tool.id"
                              @change="toolSelect"
                            >
                              <el-option
                                v-for="option in selects.tools"
                                class="select-danger"
                                :value="option.id"
                                :label="option.name"
                                :key="option"
                              >
                              </el-option>
                            </el-select>
                          </div>
                          <div class="col-md-12">
                            <el-cascader
                              style="width: 100%"
                              :disabled="selects.disabled"
                              placeholder="Select sub-speciality"
                              v-model="model.activity.id"
                              :options="selects.activities"
                              @change="setActivity"
                              :filterable="model.filterable"
                              :clearable="model.clearable"
                              collapse-tags
                            >
                            </el-cascader>
                            <p class="text-xs">
                              Current Activity: {{ model.activity.name }}
                            </p>
                          </div>
                          <div class="col-md-12 p-3" v-show="show_stats">
                            <div class="row">
                              <div class="col-md-6">
                                <ul class="list-group">
                                  <li class="list-group-item">
                                    <span class="font-weight-bold"
                                      >Reviewed</span
                                    >
                                    <span class="float-md-right text-success">{{
                                      activity_score_stats.reviewed
                                    }}</span>
                                  </li>
                                  <li class="list-group-item">
                                    <span class="font-weight-bold"
                                      >Pending</span
                                    >
                                    <span class="float-md-right text-danger">{{
                                      activity_score_stats.pending
                                    }}</span>
                                  </li>
                                  <li class="list-group-item">
                                    <span class="font-weight-bold">Total</span>
                                    <span class="float-md-right">{{
                                      activity_score_stats.total_count
                                    }}</span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul class="list-group">
                                  <li class="list-group-item">
                                    <span class="font-weight-bold"
                                      >Score Per Case</span
                                    >
                                    <span class="float-md-right">{{
                                      activity_score_stats.case_config
                                        .score_per_case
                                    }}</span>
                                  </li>
                                  <li class="list-group-item">
                                    <span class="font-weight-bold"
                                      >Current Total Score</span
                                    >
                                    <span class="float-md-right text-success">{{
                                      activity_score_stats.total_score
                                    }}</span>
                                  </li>
                                  <li class="list-group-item">
                                    <span class="font-weight-bold"
                                      >Maximum Score</span
                                    >
                                    <span class="float-md-right text-primary">{{
                                      activity_score_stats.case_config
                                        .total_max_score
                                    }}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          class="form-group input-group-alternative"
                          v-show="
                            model.tool.template != 'reports' &&
                            model.tool.template != 'dissertation'
                          "
                        >
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon3">
                                <i class="ni ni-calendar-grid-58 text-dark"></i>
                                <span class="ml-2 text-dark">Date:</span></span
                              >
                            </div>
                            <flat-picker
                              :config="{ allowInput: true }"
                              class="form-control datepicker"
                              v-model="model.template.date"
                            >
                            </flat-picker>
                          </div>
                        </div>
                        <div class="row m-1">
                          <div
                            class="form-group input-group-alternative col-sm-6"
                            v-show="
                              model.tool.template === 'reports' ||
                              model.tool.template === 'dissertation'
                            "
                          >
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon3"
                                >
                                  <i
                                    class="ni ni-calendar-grid-58 text-dark"
                                  ></i>
                                  <span class="ml-2 text-dark"
                                    >From Date:</span
                                  ></span
                                >
                              </div>
                              <flat-picker
                                :config="{ allowInput: true }"
                                class="form-control datepicker"
                                v-model="model.template.from_date"
                                :valid="model.from_date_valid"
                                @blur="model.from_date_valid = undefined"
                              >
                              </flat-picker>
                            </div>
                          </div>
                          <div
                            class="form-group input-group-alternative col-sm-6"
                            v-show="
                              model.tool.template === 'reports' ||
                              model.tool.template === 'dissertation'
                            "
                          >
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon3"
                                >
                                  <i
                                    class="ni ni-calendar-grid-58 text-dark"
                                  ></i>
                                  <span class="ml-2 text-dark"
                                    >To Date:</span
                                  ></span
                                >
                              </div>
                              <flat-picker
                                :config="{ allowInput: true }"
                                class="form-control datepicker"
                                v-model="model.template.to_date"
                                :valid="model.to_date_valid"
                                @blur="model.to_date_valid = undefined"
                              >
                              </flat-picker>
                            </div>
                          </div>
                        </div>
                        <base-input
                          formClasses="input-group-alternative"
                          placeholder="Hospital(Station/Rotation)"
                          addon-left-text="Hospital(Station/Rotation):"
                          addon-left-icon="ni ni-building text-dark"
                          v-model="model.template.station"
                          :valid="model.station_valid"
                          @blur="model.station_valid = undefined"
                        >
                        </base-input>
                        <base-input
                          v-show="
                            model.tool.template === 'presentation_and_teaching'
                          "
                          formClasses="input-group-alternative"
                          placeholder="Topic"
                          addon-left-text="Topic:"
                          addon-left-icon="ni ni-building text-dark"
                          v-model="model.template.topic"
                          :valid="model.topic_valid"
                          @blur="model.topic_valid = undefined"
                        >
                        </base-input>
                        <base-input
                          v-show="
                            model.tool.template === 'clinical_skills' ||
                            model.tool.template === 'surgical_skills'
                          "
                          formClasses="input-group-alternative"
                          placeholder="OP/IP Number"
                          addon-left-text="OP/IP Number:"
                          addon-left-icon="ni ni-single-02 text-dark"
                          v-model="model.template.patient_number"
                          :valid="model.patient_number_valid"
                          @blur="model.patient_number_valid = undefined"
                        >
                        </base-input>
                        <div
                          class="row"
                          v-show="
                            model.tool.template === 'clinical_skills' ||
                            model.tool.template === 'surgical_skills'
                          "
                        >
                          <span
                            v-show="model.dob_has_error"
                            class="ml-2 text-danger"
                            >Kindly provide the OP/IP Date of birth or Age!
                          </span>
                          <div class="col-md-5">
                            <div class="form-group input-group-alternative">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon3"
                                >
                                  <i
                                    class="ni ni-calendar-grid-58 text-dark"
                                  ></i>
                                  <span class="ml-2 text-dark"
                                    >OP/IP Date of birth:
                                  </span>
                                </span>
                              </div>

                              <flat-picker
                                :config="{ allowInput: true }"
                                class="form-control datepicker"
                                v-model="model.template.dob"
                                @onChange="calculateAge"
                              >
                              </flat-picker>
                            </div>
                          </div>
                          <div class="col-md-2">or</div>
                          <div class="col-md-5">
                            <div class="form-group input-group-alternative">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon3"
                                >
                                  <i
                                    class="ni ni-calendar-grid-58 text-dark"
                                  ></i>
                                  <span class="ml-2 text-dark"
                                    >OP/IP Age:
                                  </span>
                                </span>
                              </div>
                              <base-input
                                formClasses="input-group-alternative"
                                v-model="model.template.age"
                                :valid="model.age_valid"
                                @keyup="calculateDob"
                              ></base-input>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-show="
                            model.tool.template === 'clinical_skills' ||
                            model.tool.template === 'surgical_skills'
                          "
                        >
                          <div class="col-sm">
                            <base-radio
                              name="sex"
                              checked
                              class="mb-3"
                              @input="sexSelect"
                              value="Male"
                              v-model="model.template.sex"
                            >
                              Male
                            </base-radio>
                          </div>
                          <div class="col-sm">
                            <base-radio
                              name="sex"
                              class="mb-3"
                              @input="sexSelect"
                              value="Female"
                              v-model="model.template.sex"
                            >
                              Female
                            </base-radio>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-4"
                            v-show="
                              this.model.tool.template === 'clinical_skills' ||
                              model.tool.template === 'surgical_skills'
                            "
                          >
                            <base-textarea
                              formClasses="input-group-alternative mb-3"
                              id="diagnosis"
                              v-model="model.template.diagnosis"
                              label="Diagnosis"
                              rows="4"
                              :valid="model.diagnosis_valid"
                              @blur="model.diagnosis_valid = undefined"
                              placeholder="diagnosis"
                            />
                          </div>
                          <div
                            class="col-4"
                            v-show="model.tool.template === 'surgical_skills'"
                          >
                            <base-textarea
                              formClasses="input-group-alternative mb-3"
                              id="procedure"
                              v-model="model.template.procedure"
                              label="Procedure"
                              rows="4"
                              :valid="model.procedure_valid"
                              @blur="model.procedure_valid = undefined"
                              placeholder="procedure"
                            />
                          </div>
                          <div
                            class="col-4"
                            v-show="model.tool.template === 'surgical_skills'"
                          >
                            <base-textarea
                              formClasses="input-group-alternative mb-3"
                              id="Complication"
                              v-model="model.template.complication"
                              label="Complication"
                              rows="4"
                              :valid="model.complication_valid"
                              @blur="model.complication_valid = undefined"
                              placeholder="complication"
                            />
                          </div>

                          <div
                            class="col-4"
                            v-show="model.tool.template === 'surgical_skills'"
                          >
                            <base-textarea
                              formClasses="input-group-alternative mb-3"
                              id="intervention"
                              v-model="model.template.intervention"
                              label="Intervention"
                              rows="4"
                              :valid="model.intervention_valid"
                              @blur="model.intervention_valid = undefined"
                              placeholder="intervention"
                            />
                          </div>
                        </div>
                        <div class="row m-1">
                          Attach file
                          <div
                            class="form-group input-group-alternative col-sm-12"
                          >
                            <input
                              type="file"
                              class="custom-file-input form-control"
                              v-bind:class="{
                                'is-invalid': file_invalid,
                              }"
                              id="customFileLang"
                              lang="en"
                              @change="handleFileUpload($event)"
                            />
                            <label
                              class="custom-file-label"
                              for="customFileLang"
                              >{{ model.filename }}</label
                            >
                          </div>
                        </div>
                        <el-select
                          class="mb-3"
                          style="width: 100%"
                          placeholder="Select Supervisor"
                          v-model="model.supervisor.id"
                          :filterable="model.filterable"
                          :value="model.supervisor.id"
                          @change="supervisorSelect"
                        >
                          <el-option
                            v-for="option in selects.supervisors"
                            class="select-danger"
                            :value="option.id"
                            :label="option.displayName"
                            :key="option"
                          >
                          </el-option>
                        </el-select>
                        <p
                          v-if="model.supervisor_invalid"
                          style="color: red; font-size: small"
                        >
                          Please select a supervisor
                        </p>
                      </div>
                    </div>

                    <div class="card-footer text-right">
                      <base-button
                        type="secondary"
                        size="sm"
                        @click="caseform = false"
                        >Close</base-button
                      >
                      <base-button
                        :disabled="isDisabled"
                        nativeType="submit"
                        type="success"
                        size="sm"
                        class="my-4"
                        >save</base-button
                      >
                    </div>
                  </form>
                </div>
              </div>
            </modal>

            <modal
              v-model:show="reviewform"
              modal-classes="modal-dialog-centered modal-xl"
              body-classes="p-0"
            >
              <template v-slot:header>
                <h3>Review</h3>
              </template>
              <div class="px-lg-5 py-lg-5">
                <form
                  id="app"
                  action="#"
                  method="post"
                  role="form"
                  @submit="submit_review"
                  novalidate="true"
                >
                  <div class="row">
                    <div
                      class="col-sm-12"
                      v-show="
                        model.tool.template === 'dissertation' &&
                        model.activity.template === 'checklist'
                      "
                    >
                      <label class="col-form-label form-control-label"
                        >Check List:</label
                      >
                      <div :class="model.isChecklistValid">
                        <base-radio
                          name="score"
                          class="mb-3"
                          value="0"
                          @input="scoreSelect"
                          v-model="model.checklist"
                        >
                          0 - N/A
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="1"
                          @input="scoreSelect"
                          v-model="model.checklist"
                        >
                          1 - Very poor
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="2"
                          @input="scoreSelect"
                          v-model="model.checklist"
                        >
                          2 - Below Average
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="3"
                          @input="scoreSelect"
                          v-model="model.checklist"
                        >
                          3 - Average
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="4"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          4 - Above Average
                        </base-radio>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      class="col-sm"
                      v-show="model.tool.template === 'reports'"
                    >
                      <label class="col-form-label form-control-label"
                        >Clinical Skills:</label
                      >
                      <div :class="model.clinical_skills_valid">
                        <base-radio
                          name="clinicalSkills"
                          class="mb-3"
                          value="0"
                          @input="clinicalSkillsSelect"
                          :inline="true"
                          v-model="model.clinical_skills"
                        >
                          0 - Poor
                        </base-radio>

                        <base-radio
                          name="clinicalSkills"
                          class="custom-radio mb-3"
                          value="1"
                          @input="clinicalSkillsSelect"
                          :inline="true"
                          v-model="model.clinical_skills"
                        >
                          1 - Below Average
                        </base-radio>

                        <base-radio
                          name="clinicalSkills"
                          class="custom-radio mb-3"
                          value="2"
                          @input="clinicalSkillsSelect"
                          :inline="true"
                          v-model="model.clinical_skills"
                        >
                          2 - Average
                        </base-radio>

                        <base-radio
                          name="clinicalSkills"
                          class="custom-radio mb-3"
                          value="3"
                          @input="clinicalSkillsSelect"
                          :inline="true"
                          v-model="model.clinical_skills"
                        >
                          3 - Above Average
                        </base-radio>

                        <base-radio
                          name="clinicalSkills"
                          class="custom-radio mb-3"
                          value="4"
                          @input="clinicalSkillsSelect"
                          :inline="true"
                          v-model="model.clinical_skills"
                        >
                          4 - Excellent
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm"
                      v-show="model.tool.template === 'reports'"
                    >
                      <label class="col-form-label form-control-label"
                        >Surgical Skills:</label
                      >
                      <div :class="model.surgical_skills_valid">
                        <base-radio
                          name="surgicalSkills"
                          class="mb-3"
                          value="0"
                          @input="surgicalSkillsSelect"
                          v-model="model.surgical_skills"
                        >
                          0 - Poor
                        </base-radio>

                        <base-radio
                          name="surgicalSkills"
                          class="mb-3"
                          value="1"
                          @input="surgicalSkillsSelect"
                          v-model="model.surgical_skills"
                        >
                          1 - Below Average
                        </base-radio>

                        <base-radio
                          name="surgicalSkills"
                          class="mb-3"
                          value="2"
                          @input="surgicalSkillsSelect"
                          v-model="model.surgical_skills"
                        >
                          2 - Average
                        </base-radio>

                        <base-radio
                          name="surgicalSkills"
                          class="mb-3"
                          value="3"
                          @input="surgicalSkillsSelect"
                          v-model="model.surgical_skills"
                        >
                          3 - Above Average
                        </base-radio>

                        <base-radio
                          name="surgicalSkills"
                          class="mb-3"
                          value="4"
                          @input="surgicalSkillsSelect"
                          v-model="model.surgical_skills"
                        >
                          4 - Excellent
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm"
                      v-show="model.tool.template === 'reports'"
                    >
                      <label class="col-form-label form-control-label"
                        >Soft Skills:</label
                      >
                      <div :class="model.soft_skills_valid">
                        <base-radio
                          name="softSkills"
                          class="mb-3"
                          value="0"
                          @input="softSkillsSelect"
                          v-model="model.soft_skills"
                        >
                          0 - Poor
                        </base-radio>

                        <base-radio
                          name="softSkills"
                          class="mb-3"
                          value="1"
                          @input="softSkillsSelect"
                          v-model="model.soft_skills"
                        >
                          1 - Below Average
                        </base-radio>

                        <base-radio
                          name="softSkills"
                          class="mb-3"
                          value="2"
                          @input="softSkillsSelect"
                          v-model="model.soft_skills"
                        >
                          2 - Average
                        </base-radio>

                        <base-radio
                          name="softSkills"
                          class="mb-3"
                          value="3"
                          @input="softSkillsSelect"
                          v-model="model.soft_skills"
                        >
                          3 - Above Average
                        </base-radio>

                        <base-radio
                          name="softSkills"
                          class="mb-3"
                          value="4"
                          @input="softSkillsSelect"
                          v-model="model.soft_skills"
                        >
                          4 - Excellent
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm"
                      v-show="model.tool.template === 'reports'"
                    >
                      <label class="col-form-label form-control-label"
                        >Theory:</label
                      >
                      <div :class="model.theory_valid">
                        <base-radio
                          name="theory"
                          class="mb-3"
                          value="0"
                          @input="theorySelect"
                          v-model="model.theory"
                        >
                          0 - Poor
                        </base-radio>

                        <base-radio
                          name="theory"
                          class="mb-3"
                          value="1"
                          @input="theorySelect"
                          v-model="model.theory"
                        >
                          1 - Below Average
                        </base-radio>

                        <base-radio
                          name="theory"
                          class="mb-3"
                          value="2"
                          @input="theorySelect"
                          v-model="model.theory"
                        >
                          2 - Average
                        </base-radio>

                        <base-radio
                          name="theory"
                          class="mb-3"
                          value="3"
                          @input="theorySelect"
                          v-model="model.theory"
                        >
                          3 - Above Average
                        </base-radio>

                        <base-radio
                          name="theory"
                          class="mb-3"
                          value="4"
                          @input="theorySelect"
                          v-model="model.theory"
                        >
                          4 - Excellent
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm"
                      v-show="model.tool.template === 'reports'"
                    >
                      <label class="col-form-label form-control-label"
                        >Punctuality:</label
                      >
                      <div :class="model.punctuality_valid">
                        <base-radio
                          name="punctuality"
                          class="mb-3"
                          value="0"
                          @input="punctualitySelect"
                          v-model="model.punctuality"
                        >
                          0 - Poor
                        </base-radio>

                        <base-radio
                          name="punctuality"
                          class="mb-3"
                          value="1"
                          @input="punctualitySelect"
                          v-model="model.punctuality"
                        >
                          1 - Below Average
                        </base-radio>

                        <base-radio
                          name="punctuality"
                          class="mb-3"
                          value="2"
                          @input="punctualitySelect"
                          v-model="model.punctuality"
                        >
                          2 - Average
                        </base-radio>

                        <base-radio
                          name="punctuality"
                          class="mb-3"
                          value="3"
                          @input="punctualitySelect"
                          v-model="model.punctuality"
                        >
                          3 - Above Average
                        </base-radio>

                        <base-radio
                          name="punctuality"
                          class="mb-3"
                          value="4"
                          @input="punctualitySelect"
                          v-model="model.punctuality"
                        >
                          4 - Excellent
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm-12"
                      v-show="
                        model.tool.template != 'reports' &&
                        model.tool.template != 'dissertation' &&
                        model.tool.template != 'surgical_skills' &&
                        model.activity.template != 'surgical_skills' &&
                        model.activity.template != 'checklist'
                      "
                    >
                      <label class="col-form-label form-control-label"
                        >Level of Competence Score:</label
                      >
                      <div :class="model.isScoreValid">
                        <base-radio
                          name="score"
                          class="mb-3"
                          value="0"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          0 - Poor
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="1"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          1 - Below Average
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="2"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          2 - Average
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="3"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          3 - Above Average
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="4"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          4 - Excellent
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm-12"
                      v-show="
                        model.tool.template === 'dissertation' &&
                        model.activity.template != 'checklist'
                      "
                    >
                      <label class="col-form-label form-control-label"
                        >Level of Competence Score :</label
                      >
                      <div :class="model.isScoreValid">
                        <base-radio
                          name="score"
                          class="mb-3"
                          value="0"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          0 - Four or More Months late
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="1"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          1 - Three Months late.
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="2"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          2 - Two Months late
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="3"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          3 - One Month Late
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="4"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          4 - Within The Deadline
                        </base-radio>
                      </div>
                    </div>
                    <div
                      class="col-sm-12"
                      v-show="
                        model.tool.template === 'surgical_skills' ||
                        model.activity.template === 'surgical_skills'
                      "
                    >
                      <label class="col-form-label form-control-label"
                        >Level of Competence Score:</label
                      >
                      <div :class="model.isScoreValid">
                        <base-radio
                          name="score"
                          class="mb-3"
                          value="0"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          0 - Observed
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="1"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          1 - Assisted
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="2"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          2 - Performed with A lot of assistance
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="3"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          3 - Performed with Minimum assistance
                        </base-radio>

                        <base-radio
                          name="score"
                          class="mb-3"
                          value="4"
                          @input="scoreSelect"
                          v-model="model.competence"
                        >
                          4 - Performed Independently
                        </base-radio>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <base-textarea
                        formClasses="input-group-alternative mb-3"
                        id="comment"
                        v-model="model.comment"
                        label="Comment"
                        rows="4"
                        :valid="model.comment_valid"
                        @blur="model.comment_valid = undefined"
                        placeholder="comment"
                      />
                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <base-button
                      type="secondary"
                      size="sm"
                      @click="reviewform = false"
                      >Close</base-button
                    >

                    <base-button nativeType="submit" type="success" size="sm"
                      >Submit Review</base-button
                    >
                  </div>
                </form>
              </div>
            </modal>

            <modal
              v-model:show="scoremodal"
              modal-classes="modal-dialog-centered modal-lg"
              body-classes="p-0"
            >
              <div
                class="card shadow"
                :class="type === 'dark' ? 'bg-default' : ''"
              >
                <div
                  class="card-header border-0"
                  :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h3
                        class="mb-0"
                        :class="type === 'dark' ? 'text-white' : ''"
                      >
                        Results: {{ case_code }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="card-body px-lg-2 py-lg-2">
                  <div class="row">
                    <div class="col-sm-3">Comment:</div>

                    <div class="col-sm-9 text-wrap">
                      <span style="white-space: pre-wrap">{{ comment }}</span>
                    </div>
                  </div>
                  <div class="row" v-for="(item, key) in feedback" :key="key">
                    <div class="col-sm-3">
                      {{
                        (key.charAt(0).toUpperCase() + key.substring(1))
                          .replace("_", " ")
                          .replace("", " ")
                      }}
                    </div>
                    <div class="col-sm-9">
                      <p class="text-wrap">
                        <b>{{ scr[item] }}</b>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">Score:</div>
                    <div class="col-sm-9">
                      <b>{{ score }}</b>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <base-button
                    type="secondary"
                    size="sm"
                    @click="scoremodal = false"
                    >Close</base-button
                  >
                </div>
              </div>
            </modal>

            <modal
              v-model:show="reasonmodal"
              modal-classes="modal-dialog-centered modal-lg"
              body-classes="p-0"
            >
              <div
                class="card shadow"
                :class="type === 'dark' ? 'bg-default' : ''"
              >
                <div
                  class="card-header border-0"
                  :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h3
                        class="mb-0"
                        :class="type === 'dark' ? 'text-white' : ''"
                      >
                        Case: {{ case_code }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="card-body px-lg-2 py-lg-2">
                  <div class="row">
                    <div class="col-sm-3">Reason:</div>

                    <div class="col-sm-9">
                      <p class="text-wrap">
                        {{ reason }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <base-button
                    type="secondary"
                    size="sm"
                    @click="reasonmodal = false"
                    >Close</base-button
                  >
                </div>
              </div>
            </modal>

            <modal
              v-model:show="confirm_delete_visible"
              modal-classes="modal-dialog-centered"
              body-classes="bg-secondary"
            >
              <template v-slot:header>
                <h5 class="modal-title" id="modal-title-default">
                  Confirm Deletion for Case No. {{ model.code }}
                </h5>
              </template>
              <div class="form-group">
                <base-textarea
                  formClasses="input-group-alternative mb-3"
                  id="delete_reason"
                  label="Reason"
                  rows="4"
                  v-model="model.reason"
                  :valid="delete_reason_valid"
                  @blur="delete_reason_valid = undefined"
                  placeholder="Type reason here ..."
                />
              </div>

              <template v-slot:footer>
                <base-button
                  type="secondary"
                  class="ml-auto"
                  size="sm"
                  @click="confirm_delete_visible = false"
                  >Close
                </base-button>
                <base-button type="danger" size="sm" @click="delete_item_admin"
                  >Delete</base-button
                >
              </template>
            </modal>
            <modal
              v-model:show="confirm_reset_visible"
              modal-classes="modal-dialog-centered"
              body-classes="bg-secondary"
            >
              <template v-slot:header>
                <h5 class="modal-title" id="modal-title-default">
                  Confirm Reset for Case No. {{ model.code }}
                </h5>
              </template>

              <div class="form-group">
                <base-textarea
                  formClasses="input-group-alternative mb-3"
                  id="delete_reason"
                  label="Reason"
                  rows="4"
                  v-model="model.reason"
                  :valid="reset_reason_valid"
                  @blur="reset_reason_valid = undefined"
                  placeholder="Type reason here ..."
                />
              </div>

              <template v-slot:footer>
                <base-button
                  type="secondary"
                  class="ml-auto"
                  size="sm"
                  @click="confirm_reset_visible = false"
                  >Close
                </base-button>
                <base-button type="danger" size="sm" @click="reset_item_admin"
                  >Reset</base-button
                >
              </template>
            </modal>
            <base-alert v-if="show_alert" :type="alert_type" dismissible>
              <div v-for="(row, index) in alert_response" :key="row">
                <span class="alert-inner--icon"
                  ><i class="fa fa-exclamation"></i
                ></span>

                <span class="alert-inner--text"
                  ><strong>{{ index }}</strong> {{ row }}</span
                >
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                tbody-classes="list"
                :data="cases"
              >
                <template v-slot:columns>
                  <th></th>
                  <th>No.</th>
                  <th>Activity</th>
                  <th>Case Details</th>
                  <th>Status</th>
                  <th>Submitted By</th>
                  <th>Supervisor</th>
                  <th>Created</th>
                </template>

                <template v-slot:default="row">
                  <td class="text-right">
                    <base-dropdown class="dropdown" position="right">
                      <template v-slot:title>
                        <a
                          class="btn btn-sm btn-icon-only text-light"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </a>
                      </template>
                      <div v-if="!row.item.is_reviewed && !row.item.trashed">
                        <a
                          v-if="user.role == 'Student'"
                          class="dropdown-item text-green"
                          href="javascript:void(0)"
                          @click="edit(row.item)"
                          ><i class="ni ni-fat-add"></i>Edit</a
                        >
                        <a
                          v-if="user.role == 'Student' && !row.item.trashed"
                          class="dropdown-item text-danger"
                          href="javascript:void(0)"
                          @click="delete_item(row.item)"
                          ><i class="ni ni-fat-remove" aria-hidden="true"></i
                          >Delete</a
                        >
                        <div
                          v-if="user.role == 'Student' && !row.item.trashed"
                          class="dropdown-divider"
                        ></div>

                        <a
                          v-if="
                            user.id === row.item.supervisor.id ||
                            global_admin ||
                            (user.role == 'Student' && !row.item.trashed)
                          "
                          class="dropdown-item text-blue"
                          href="javascript:void(0)"
                          @click="review(row.item)"
                          ><i class="ni ni-check-bold"></i>Review</a
                        >
                      </div>

                      <a
                        v-if="
                          (user.id === row.item.supervisor.id ||
                            (global_admin && !row.item.trashed)) &&
                          !row.item.is_approved
                        "
                        class="dropdown-item text-green"
                        href="javascript:void(0)"
                        @click="approve(row.item)"
                        ><i class="ni ni-check-bold"></i>Approve</a
                      >

                      <a
                        v-if="user.role == 'Student' && row.item.trashed"
                        class="dropdown-item text-green"
                        href="javascript:void(0)"
                        @click="edit(row.item)"
                        ><i class="ni ni-fat-add"></i>Re-submit</a
                      >

                      <a
                        v-if="row.item.is_reviewed && !row.item.trashed"
                        class="dropdown-item text-primary"
                        href="javascript:void(0)"
                        @click="view_result(row.item)"
                        ><i class="ni ni-zoom-split-in" aria-hidden="true"></i
                        >View score</a
                      >
                      <a
                        v-if="row.item.trashed"
                        class="dropdown-item text-primary"
                        href="javascript:void(0)"
                        @click="view_reason(row.item)"
                        ><i class="ni ni-zoom-split-in" aria-hidden="true"></i
                        >View reason</a
                      >
                      <div v-if="is_admin" class="dropdown-divider"></div>
                      <a
                        v-if="is_admin && !row.item.trashed"
                        class="dropdown-item text-danger"
                        href="javascript:void(0)"
                        @click="confirm_delete_item_admin(row.item)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>Remove</a
                      >

                      <a
                        v-if="is_admin && !row.item.trashed"
                        class="dropdown-item text-primary"
                        href="javascript:void(0)"
                        @click="confirm_reset_item_admin(row.item)"
                        ><i class="fa fa-undo" aria-hidden="true"></i>Reset
                        Score</a
                      >
                    </base-dropdown>
                  </td>
                  <td>{{ row.item.code }}</td>
                  <td>
                    <div
                      v-html="row.item.full_activity_name"
                      class="text-wrap"
                      style="width: 8rem"
                    ></div>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col-md-12">
                        <b>Year: </b>{{ row.item.academicYear.name }}
                      </div>
                      <div class="col-md-12">
                        <b>Tool: </b>{{ row.item.tool.name }}
                      </div>
                    </div>
                    <el-popover placement="left" :width="500" trigger="click">
                      <div class="row">
                        <div class="col-sm-6">
                          <h3 class="popover-header">
                            {{ row.item.code }}
                          </h3>
                        </div>
                        <div class="col-sm-6 text-small">
                          Submitted By:
                          {{ row.item.user.name }}
                        </div>
                        <table class="table align-items-left table-dark">
                          <tbody class="list">
                            <tr
                              v-for="(item, key) in row.item.template"
                              :key="key"
                              class="text-left"
                            >
                              <td v-if="item != ''">
                                {{
                                  key == "dob"
                                    ? key.toUpperCase()
                                    : (
                                        key.charAt(0).toUpperCase() +
                                        key.substring(1)
                                      )
                                        .replace("_", " ")
                                        .replace("", " ")
                                }}
                                :
                                {{
                                  key == "dob"
                                    ? item + "  (" + yearDiff(item) + "yrs)"
                                    : item
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div class="col-sm-12" v-show="row.item.upload != null">
                          <h5>Attachment</h5>
                          <hr class="my-3" />
                          <div class="row">
                            <div class="col-md-8">
                              {{
                                row.item.upload != null
                                  ? row.item.upload.name
                                  : ""
                              }}
                            </div>
                            <div class="col-md-4">
                              <a
                                :href="
                                  row.item.upload != null
                                    ? row.item.upload.path
                                    : ''
                                "
                                target="_blank"
                              >
                                <i class="fa fa-search-plus mr-3"></i>
                                <span>View</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template #reference>
                        <a href="javascript:void(0)">View details</a>
                      </template>
                    </el-popover>
                  </td>
                  <td>
                    <div v-if="row.item.trashed">
                      <badge :type="'danger'">
                        <span class="status">Trashed</span>
                      </badge>
                    </div>
                    <div v-else>
                      <badge :type="row.item.is_reviewed ? 'success' : 'info'">
                        <span class="status">{{
                          row.item.is_reviewed ? "Reviewed" : "Pending review"
                        }}</span>
                      </badge>
                    </div>
                    <div v-if="row.item.is_reviewed && !row.item.trashed">
                      <badge :type="row.item.is_approved ? 'primary' : 'info'">
                        <span class="status">{{
                          row.item.is_approved ? "Approved" : "Pending Approval"
                        }}</span>
                      </badge>
                    </div>
                  </td>
                  <th>
                    {{ row.item.user.name }}
                  </th>
                  <th>
                    {{ row.item.supervisor.name }}
                  </th>
                  <th>
                    {{ row.item.created_at }}
                  </th>
                </template>
              </base-table>
            </div>

            <div
              class="card-footer d-flex justify-content-end"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <base-pagination
                :total="page.total"
                :perPage="page.per_page"
                :value="page.current"
                @paginate="getMyCases"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElSelect,
  ElOption,
  ElCascader,
  ElButton,
  ElPopover,
} from "element-plus";
import "vue3-treeselect/dist/vue3-treeselect.css";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  name: "cases-table",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElCascader.name]: ElCascader,
    [ElButton.name]: ElButton,
    [ElPopover.name]: ElPopover,
    flatPicker,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      show_stats: false,
      activity_score_stats: {
        reviewed: 0,
        pending: 0,
        total_count: 0,
        total_score: 0,
        case_config: {
          min_case: 0,
          score_per_case: 0,
          total_max_score: 0,
        },
      },
      confirm_delete_visible: false,
      confirm_reset_visible: false,
      filter_visible: false,
      filter: {
        supervisor: { id: null },
        student: { id: null },
        status: { id: null },
        case_no: "",
        tool: { id: null },
        academic_year: { id: null },
        activity: { id: null },
      },
      states: [
        {
          id: "0",
          name: "Pending Review",
        },
        {
          id: "1",
          name: "Reviewed",
        },
        {
          id: "2",
          name: "Pending Approval",
        },
        {
          id: "3",
          name: "Approved",
        },
        {
          id: "4",
          name: "Trashed",
        },
      ],
      studentslist: [],
      supervisorslist: [],
      loading: false,
      page: {
        current: 1,
        total: 1,
        per_page: 1,
      },
      filterable: true,
      clearable: true,
      scr: [],
      scr_dissertation: [],
      scr_checklist: [],
      scr_surgical: [],
      cases: [],
      caseform: false,
      reviewform: false,
      scoremodal: false,
      reasonmodal: false,
      delete_reason_valid: undefined,
      reset_reason_valid: undefined,
      case_code: "",
      score: "",
      comment: "",
      competence: null,
      reason: "",
      req: null,
      feedback: null,
      dob_has_error: false,
      radio: {
        poor: null,
        below_average: null,
        average: null,
        above_average: null,
        excellent: null,
      },
      isDisabled: true,
      isScoreValid: "",
      file_invalid: false,
      model: {
        reason: "",
        file: undefined,
        filename: "",
        id: null,
        competence: null,
        comment: "",
        clinical_skills: null,
        soft_skills: null,
        surgical_skills: null,
        theory: null,
        punctuality: null,
        isChecklistValid: "",
        punctuality_valid: "",
        theory_valid: "",
        soft_skills_valid: "",
        clinical_skills_valid: "",
        surgical_skills_valid: "",
        template_name: "",
        template: {
          date: "",
          from_date: "",
          to_date: "",
          station: "",
          topic: "",
          patient_number: "",
          dob: "",
          age: 0,
          sex: "Male",
          diagnosis: "",
          procedure: "",
          complication: "",
          intervention: "",
        },
        isvalid: 1,
        tool: { id: null, template: null },
        activity: { id: null, name: null, template: null },
        supervisor: { id: null },
        user: { id: null, name: null },
      },
      selects: {
        activities: [],
        tools: [],
        academic_years: [],
        tool_template_array: {},
        supervisors: [],
        disabled: true,
      },
    };
  },
  mounted: function () {
    this.getYears();
    this.getTools();
    this.getSupervisors("");
    this.getMyCases(1);
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
    user() {
      return this.$store.state.api.user;
    },
    is_admin() {
      return this.$store.state.api.is_admin;
    },
    global_admin() {
      return this.$store.state.api.global_admin;
    },
  },

  methods: {
    calculateAge() {
      if (this.model.template.dob != "") {
        this.model.template.age = this.yearDiff(this.model.template.dob);
      }
    },
    calculateDob() {
      if (this.model.template.age != "") {
        this.model.template.dob = moment()
          .subtract(parseInt(this.model.template.age), "years")
          .format("YYYY-01-01");
      }
    },
    confirm_delete_item_admin(record) {
      this.model = record;
      this.model.reason = "";
      this.confirm_delete_visible = true;
    },
    confirm_reset_item_admin(record) {
      this.model = record;
      this.model.reason = "";
      this.confirm_reset_visible = true;
    },
    delete_item_admin() {
      if (this.model.reason != "") {
        this.deleteCase();
      } else {
        this.delete_reason_valid = false;
      }
    },
    reset_item_admin() {
      if (this.model.reason != "") {
        this.resetScore();
      } else {
        this.reset_reason_valid = false;
      }
    },
    filterCases() {
      this.getMyCases(1);
    },
    filterStudents(query) {
      if (query !== "") {
        this.loading = true;
        this.searchStudents(query);
      } else {
        this.studentslist = [];
      }
    },
    filterSupervisors(query) {
      if (query !== "") {
        this.loading = true;
        this.searchSupervisors(query);
      } else {
        this.supervisorslist = [];
      }
    },
    handleFileUpload(event) {
      this.model.file = event.target.files[0];
      this.model.filename = event.target.files[0].name;
      this.file_invalid = false;
    },
    yearDiff(birthday) {
      var ageDifMs = Date.now() - new Date(birthday);
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    view_reason(item) {
      this.reason = item.comment;
      this.case_code = item.code;
      this.reasonmodal = true;
    },
    view_result(item) {
      this.scr.push("0 - Poor");
      this.scr.push("1 - Below Average");
      this.scr.push("2 - Average");
      this.scr.push("3 - Above Average");
      this.scr.push("4 - Excellent");

      this.scr_checklist.push("0 - N/A");
      this.scr_checklist.push("1 - Very poor");
      this.scr_checklist.push("2 - Below Average");
      this.scr_checklist.push("3 - Average");
      this.scr_checklist.push("4 - Above Average");

      this.scr_dissertation.push("0 - Four or More Months late");
      this.scr_dissertation.push("1 - Three months late");
      this.scr_dissertation.push("2 - Two Months Late");
      this.scr_dissertation.push("3 - One Month Late");
      this.scr_dissertation.push("4 - Within The Deadline");

      this.scr_surgical.push("0 - Observed");
      this.scr_surgical.push("1 - Assisted");
      this.scr_surgical.push("2 - Performed with A lot of assistance");
      this.scr_surgical.push("3 - Performed with Minimum assistance");
      this.scr_surgical.push("4 - Performed Independently");

      this.feedback = item.feedback;
      this.comment = item.comment;

      if (this.selects.tool_template_array[item.tool.id] == "reports")
        this.score = item.score;
      else if (
        this.selects.tool_template_array[item.tool.id] == "dissertation" &&
        item.activity.template != "checklist"
      )
        this.score = this.scr_dissertation[item.score];
      else if (
        this.selects.tool_template_array[item.tool.id] == "dissertation" &&
        item.activity.template == "checklist"
      )
        this.score = this.scr_checklist[item.score];
      else if (
        this.selects.tool_template_array[item.tool.id] == "surgical_skills" ||
        item.activity.template == "surgical_skills"
      )
        this.score = this.scr_surgical[item.score];
      else this.score = this.scr[item.score];

      this.case_code = item.code;
      this.scoremodal = true;
    },
    sexSelect(value) {
      this.model.sex = value;
    },
    scoreSelect(value) {
      this.model.competence = value;
    },
    clinicalSkillsSelect(value) {
      this.model.clinical_skills = parseInt(value);
    },
    surgicalSkillsSelect(value) {
      this.model.surgical_skills = parseInt(value);
    },
    softSkillsSelect(value) {
      this.model.soft_skills = parseInt(value);
    },
    theorySelect(value) {
      this.model.theory = parseInt(value);
    },
    punctualitySelect(value) {
      this.model.punctuality = parseInt(value);
    },
    setActivity(node) {
      this.isDisabled = false;
      this.model.activity.id = node[node.length - 1];
      this.show_stats = false;
      this.getActivityScoreSummary();

      //model.activity.name
    },

    setActivityFilter(node) {
      if (node != null) this.filter.activity.id = node[node.length - 1];
    },
    toolSelectFilter(val) {
      if (val == "") {
        this.selects.disabled = true;
        this.filter.activity.id = null;
      } else {
        this.selects.disabled = false;
        this.filter.tool.id = val;
        this.getActivities(val);
      }
    },
    academicYearSelectFilter(val) {
      if (val == "") {
        this.filter.academic_year.id = null;
      } else {
        this.filter.academic_year.id = val;
      }
    },

    currentDate() {
      const current = new Date();
      const current_date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      this.model.template.date = current_date;
      this.model.template.from_date = current_date;
      this.model.template.to_date = current_date;
    },
    toolSelect(val) {
      this.selects.disabled = false;
      this.model.tool.id = val;
      this.model.tool.template = this.selects.tool_template_array[val];
      this.getActivities(val);
    },
    studentSelect(val) {
      this.filter.student.id = val;
    },
    searchSupervisorSelect(val) {
      this.filter.supervisor.id = val;
    },
    reviewStatusSelect(val) {
      this.filter.status.id = val;
    },

    supervisorSelect(val) {
      this.model.supervisor.id = val;
      this.model.supervisor_invalid = undefined;
    },
    addnew: function () {
      this.model.id = null;
      this.resetForm();
      this.caseform = true;
      this.currentDate();
    },
    edit: function (record) {
      this.isvalid = 1;
      this.isDisabled = false;

      this.model.tool.template = this.selects.tool_template_array[
        record.tool.id
      ];
      this.model.id = record.id;
      this.model.tool.id = record.tool.id;
      this.model.activity.id = record.activity.id;
      this.model.activity.name = record.activity.name;
      this.model.supervisor.id = record.supervisor.id;
      this.getActivities(record.tool.id);
      this.model.template = record.template;

      this.caseform = true;
      this.getActivityScoreSummary();
    },
    review: function (record) {
      this.model = record;
      this.model.tool.template = this.selects.tool_template_array[
        record.tool.id
      ];
      this.reviewform = true;
    },

    approve: function (record) {
      this.model = record;
      this.$swal({
        title: "Are you sure ?",
        text:
          "Please confirm all data has been reviewed properly before approving. You won't be able to revert this action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#38ce89",
        cancelButtonColor: "#5e72e4",
        confirmButtonText: "Yes, proceed!",
      }).then((result) => {
        if (result.value) {
          this.req = {
            id: this.model.id,
          };
          this.$store.dispatch("api/approve_case", this.req).then(
            (response) => {
              var idx = this.cases.indexOf(this.model);
              this.cases.splice(idx, 1, response.data);

              this.$swal(
                "Success!",
                "Your approval has been submitted.",
                "success"
              );
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
              this.$swal(
                "Error!",
                "An error was encountered. Please try again",
                "error"
              );
            }
          );
        }
      });
    },
    delete_item: function (record) {
      this.model = record;
      this.deleteCase();
    },
    submit_review: function (e) {
      this.model.isScoreValid = "";
      this.hasError = false;
      this.isvalid = 1;
      var feedback = {
        punctuality: "",
        theory: "",
        soft_skills: "",
        clinical_skills: "",
        surgical_skills: "",
      };

      this.model.punctuality_valid = "";
      this.model.theory_valid = "";
      this.model.soft_skills_valid = "";
      this.model.surgical_skills_valid = "";
      this.model.clinical_skills_valid = "";

      if (this.model.tool.template === "reports") {
        if (this.model.punctuality == null) {
          this.isvalid = this.isvalid * 0;
          this.model.punctuality_valid = "text-danger";
        } else {
          feedback.punctuality = this.model.punctuality;
        }

        if (this.model.theory == null) {
          this.isvalid = this.isvalid * 0;
          this.model.theory_valid = "text-danger";
        } else {
          feedback.theory = this.model.theory;
        }
        if (this.model.soft_skills == null) {
          this.isvalid = this.isvalid * 0;
          this.model.soft_skills_valid = "text-danger";
        } else {
          feedback.soft_skills = this.model.soft_skills;
        }
        if (this.model.surgical_skills == null) {
          this.isvalid = this.isvalid * 0;
          this.model.surgical_skills_valid = "text-danger";
        } else {
          feedback.surgical_skills = this.model.surgical_skills;
        }
        if (this.model.clinical_skills == null) {
          this.isvalid = this.isvalid * 0;
          this.model.clinical_skills_valid = "text-danger";
        } else {
          feedback.clinical_skills = this.model.clinical_skills;
        }
        this.model.competence =
          feedback.punctuality +
          feedback.theory +
          feedback.soft_skills +
          feedback.surgical_skills +
          feedback.clinical_skills;
      }

      if (!this.model.comment) {
        this.isvalid = this.isvalid * 0;
        this.model.comment_valid = false;
      }

      if (
        this.model.competence == null &&
        this.model.tool.template != "reports"
      ) {
        this.isvalid = this.isvalid * 0;
        this.model.isScoreValid = "text-danger";
      }

      if (
        this.model.competence == null &&
        this.model.activity.template == "checklist"
      ) {
        this.isvalid = this.isvalid * 0;
        this.model.isChecklistValid = "text-danger";
      }

      if (this.isvalid) {
        if (feedback.punctuality != "") {
          this.req = {
            id: this.model.id,
            result: this.model.competence,
            feedback: JSON.stringify(feedback),
            comment: this.model.comment,
          };
        } else {
          this.req = {
            id: this.model.id,
            result: this.model.competence,
            comment: this.model.comment,
          };
        }
        this.$swal({
          title: "Are you sure ?",
          text:
            "Confirm all data has been keyed in correctly. You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#38ce89",
          cancelButtonColor: "#5e72e4",
          confirmButtonText: "Yes, proceed!",
        }).then((result) => {
          if (result.value) {
            this.submitReview();
            feedback = {
              punctuality: "",
              theory: "",
              soft_skills: "",
              clinical_skills: "",
              surgical_skills: "",
            };
          }
        });
      }
      e.preventDefault();
    },
    submitReview() {
      this.$store.dispatch("api/review_case", this.req).then(
        (response) => {
          if (response.success) {
            this.reviewform = false;
            var idx = this.cases.indexOf(this.model);
            this.model = response.data;
            this.resetReviewForm();
            this.cases.splice(idx, 1, response.data);
            this.$swal(
              "Success!",
              "Your review has been submitted.",
              "success"
            );
          }
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
          this.reviewform = false;
        }
      );
    },
    getActivityScoreSummary() {
      this.$store
        .dispatch("api/activity_score_summary", this.model.activity.id)
        .then(
          (response) => {
            this.activity_score_stats = response.data;
            this.show_stats = true;
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            console.log(error);
            this.show_stats = false;
          }
        );
    },
    resetReviewForm() {
      this.model = {
        comment: "",
        competence: null,
        clinical_skills: null,
        soft_skills: null,
        surgical_skills: null,
        theory: null,
        punctuality: null,
        feedback: null,
        template: {
          date: "",
          from_date: "",
          to_date: "",
          station: "",
          topic: "",
          patient_number: "",
          dob: "",
          age: 0,
          sex: "Male",
          diagnosis: "",
          procedure: "",
          complication: "",
          intervention: "",
        },
        tool: { id: null, template: null },
        activity: { id: null, name: null, template: null },
        supervisor: { id: null },
        user: { id: null, name: null },
      };
    },
    submitData: function (e) {
      this.hasError = false;
      this.model.dob_has_error = false;
      this.isvalid = 1;
      if (
        this.model.tool.template === "reports" ||
        this.model.tool.template === "dissertation"
      ) {
        if (!this.model.template.to_date) {
          this.isvalid = this.isvalid * 0;
          this.model.to_date_valid = false;
        }
        if (!this.model.template.from_date) {
          this.isvalid = this.isvalid * 0;
          this.model.from_date_valid = false;
        }
        delete this.model.template.date;
        delete this.model.template.topic;
        delete this.model.template.patient_number;
        delete this.model.template.dob;
        delete this.model.template.sex;
        delete this.model.template.diagnosis;
        delete this.model.template.procedure;
        delete this.model.template.complication;
        delete this.model.template.intervention;
      }

      if (this.model.tool.template === "surgical_skills") {
        if (!this.model.template.intervention) {
          this.isvalid = this.isvalid * 0;
          this.model.intervention_valid = false;
        }
        if (!this.model.template.complication) {
          this.isvalid = this.isvalid * 0;
          this.model.complication_valid = false;
        }
        if (!this.model.template.procedure) {
          this.isvalid = this.isvalid * 0;
          this.model.procedure_valid = false;
        }
        delete this.model.template.from_date;
        delete this.model.template.to_date;
        delete this.model.template.topic;
      }
      if (this.model.tool.template === "clinical_skills") {
        delete this.model.template.from_date;
        delete this.model.template.to_date;
        delete this.model.template.topic;
        delete this.model.template.procedure;
        delete this.model.template.complication;
        delete this.model.template.intervention;
      }
      if (
        this.model.tool.template === "clinical_skills" ||
        this.model.tool.template === "surgical_skills"
      ) {
        if (!this.model.template.diagnosis) {
          this.isvalid = this.isvalid * 0;
          this.model.diagnosis_valid = false;
        }
        if (!this.model.template.dob) {
          this.isvalid = this.isvalid * 0;
          this.model.dob_has_error = true;
        }
        if (!this.model.template.patient_number) {
          this.isvalid = this.isvalid * 0;
          this.model.patient_number_valid = false;
        }
      }
      if (this.model.tool.template === "presentation_and_teaching") {
        if (!this.model.template.topic) {
          this.isvalid = this.isvalid * 0;
          this.model.topic_valid = false;
        }

        delete this.model.template.from_date;
        delete this.model.template.to_date;
        delete this.model.template.patient_number;
        delete this.model.template.dob;
        delete this.model.template.sex;
        delete this.model.template.diagnosis;
        delete this.model.template.procedure;
        delete this.model.template.complication;
        delete this.model.template.intervention;
      }

      if (
        this.model.tool.template != "reports" &&
        this.model.tool.template != "dissertation"
      ) {
        if (!this.model.template.date) {
          this.isvalid = this.isvalid * 0;
          this.model.date_valid = false;
        }
      }

      if (!this.model.template.station) {
        this.isvalid = this.isvalid * 0;
        this.model.station_valid = false;
      }

      if (this.model.file == undefined) {
        this.isvalid = this.isvalid * 0;
        this.file_invalid = true;
      }

      if (!this.model.supervisor.id) {
        this.isvalid = this.isvalid * 0;
        this.model.supervisor_invalid = true;
      }
      if (this.isvalid) {
        let formData = new FormData();
        if (this.model.file != undefined)
          formData.append("document", this.model.file);
        formData.append("tool_id", this.model.tool.id);
        formData.append(
          "academic_year_id",
          this.$store.state.api.academic_year_id
        );
        formData.append("activity_id", this.model.activity.id);
        formData.append("template", JSON.stringify(this.model.template));
        formData.append("supervisor_id", this.model.supervisor.id);
        formData.append("id", this.model.id != null ? this.model.id : null);

        this.model.id != null
          ? this.updateCase(formData)
          : this.createCase(formData);
      }

      e.preventDefault();
    },
    getTools() {
      this.$store.dispatch("api/get_tools").then(
        (response) => {
          this.selects.tools = response.data;
          response.data.forEach((item) => {
            this.selects.tool_template_array[item.id] = item.template;
          });
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getYears() {
      this.$store.dispatch("api/get_years").then(
        (response) => {
          this.selects.academic_years = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getSupervisors() {
      this.$store.dispatch("api/get_supervisors").then(
        (response) => {
          this.selects.supervisors = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    searchSupervisors(search) {
      this.$store.dispatch("api/search_supervisors", search).then(
        (response) => {
          this.loading = false;
          this.supervisorslist = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    searchStudents(search) {
      this.$store.dispatch("api/search_students", search).then(
        (response) => {
          this.loading = false;

          this.studentslist = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getActivities(val) {
      this.$store.dispatch("api/get_activities", val).then(
        (response) => {
          this.selects.activities = response.data.data;
          this.selects.disabled = false;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getMyCases(page) {
      this.page.current = page;
      var request = {
        pagination: this.page,
        filter: this.filter,
      };

      this.$store.dispatch("api/get_cases", request).then(
        (response) => {
          this.page.current = response.metas.current_page;
          this.page.per_page = response.metas.per_page;
          this.page.total = response.metas.total;
          this.cases = response.data;
          this.filter_visible = false;
        },

        (error) => {
          console.log(error);
        }
      );
    },
    createCase(req) {
      this.$store.dispatch("api/add_case", req).then(
        (response) => {
          this.resetForm();
          this.caseform = false;
          this.cases.unshift(response.data);

          this.model.file = null;
          this.model.filename = null;
        },
        // eslint-disable-next-line no-unused-vars
        () => {
          this.caseform = false;
        }
      );
    },
    updateCase(req) {
      this.$store.dispatch("api/update_case", req).then(
        (response) => {
          this.resetForm();
          this.caseform = false;
          var idx = this.cases.indexOf(this.model);
          this.cases.splice(idx, 1, response.data);

          this.model.file = null;
          this.model.filename = null;
        },
        // eslint-disable-next-line no-unused-vars
        () => {
          this.caseform = false;
        }
      );
    },
    resetForm() {
      this.model = {
        tool: { id: null, template: null },
        activity: { id: null, name: null },
        supervisor: { id: null },
        user: { id: null, name: null },
      };
      this.model.template = {
        date: "",
        from_date: "",
        to_date: "",
        station: "",
        topic: "",
        patient_number: "",
        dob: "",
        sex: "Male",
        diagnosis: "",
        procedure: "",
        complication: "",
        intervention: "",
      };
    },
    deleteCase() {
      this.$store.dispatch("api/del_case", this.model).then(
        // eslint-disable-next-line no-unused-vars
        () => {
          this.confirm_delete_visible = false;
          var idx = this.cases.indexOf(this.model);
          this.cases.splice(idx, 1);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Record deleted successfully",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Failed to delete record.",
          });
          console.log(error);
        }
      );
    },
    resetScore() {
      this.$store.dispatch("api/reset_score", this.model).then(
        // eslint-disable-next-line no-unused-vars
        () => {
          this.confirm_reset_visible = false;
          var idx = this.cases.indexOf(this.model);
          this.cases.splice(idx, 1);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Record reset successful",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Failed to reset score.",
          });
          console.log(error);
        }
      );
    },
  },
};
</script>
<style>
.dropdown-menu-right {
  right: 0 !important;
  left: 0 !important;
}
</style>
