<template>
  <div class="row">
    <div class="col-sm-8">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} List
              </h3>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="departments"
          >
            <template v-slot:columns>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </template>

            <template v-slot:default="row">
              <th scope="row">
                {{ row.item.id }}
              </th>
              <th scope="row">
                {{ row.item.name }}
              </th>
              <td>
                {{
                  row.item.description.length > 100
                    ? row.item.description.substring(0, 100) + "..."
                    : row.item.description
                }}
              </td>

              <td class="text-right">
                <base-dropdown class="dropdown" position="right">
                  <template v-slot:title>
                    <a
                      class="btn btn-sm btn-icon-only text-light"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>
                  </template>

                  <a
                    class="dropdown-item text-green"
                    href="javascript:void(0)"
                    @click="edit(row.item)"
                    ><i class="ni ni-fat-add"></i>Edit</a
                  >
                  <a
                    class="dropdown-item text-danger"
                    href="javascript:void(0)"
                    @click="delete_item(row.item)"
                    ><i class="ni ni-fat-remove" aria-hidden="true"></i
                    >Delete</a
                  >
                </base-dropdown>
              </td>
            </template>
          </base-table>
        </div>

        <div
          class="card-footer d-flex justify-content-end"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <base-pagination total="30"></base-pagination>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                {{ title }} Form
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form
            id="app"
            action="#"
            method="post"
            role="form"
            @submit="submitData"
            novalidate="true"
          >
            <base-alert v-if="show_alert" :type="alert_type" dismissible>
              <div v-for="(row, index) in alert_response" :key="row">
                <span class="alert-inner--icon"
                  ><i class="fa fa-exclamation"></i
                ></span>

                <span class="alert-inner--text"
                  ><strong>{{ index }}</strong> {{ row }}</span
                >
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Name"
              v-model="model.name"
              :valid="model.name_valid"
              @blur="model.name_valid = undefined"
              :error="model.name_error"
            >
            </base-input>
            <base-textarea
              formClasses="input-group-alternative mb-3"
              id="description"
              rows="5"
              v-model="model.description"
              :valid="model.description_valid"
              @blur="model.description_valid = undefined"
              placeholder="Write a description of what the department deals with ..."
            />

            <div class="text-right">
              <base-button nativeType="submit" type="primary" class="my-4"
                >save</base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "departments-table",
  show_alert: false,
  alert_type: "",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      departments: null,
      isvalid: 1,
      model: {
        id: null,
        name: "",
        description: "",
      },
    };
  },
  mounted: function () {
    this.getDepartments();
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  methods: {
    edit: function (record) {
      this.isvalid = 1;
      this.model = record;
    },
    delete_item: function (record) {
      this.model = record;
      this.deleteDepartment();
    },
    submitData: function (e) {
      this.hasError = false;
      this.isvalid = 1;
      if (!this.model.name) {
        this.isvalid = this.isvalid * 0;
        this.model.name_valid = false;
      }
      if (!this.model.description) {
        this.isvalid = this.isvalid * 0;
        this.model.description_valid = false;
      }
      if (this.isvalid) {
        this.model.id != null
          ? this.updateDepartment()
          : this.createDepartment();
      }

      e.preventDefault();
    },
    getDepartments() {
      this.$store.dispatch("api/get_departments").then(
        (response) => {
          this.departments = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    createDepartment() {
      this.$store.dispatch("api/add_department", this.model).then(
        (response) => {
          this.departments.push(response.data);
          this.model.name = null;
          this.model.description = null;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {}
      );
    },
    updateDepartment() {
      this.$store.dispatch("api/update_department", this.model).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {},
        // eslint-disable-next-line no-unused-vars
        (error) => {}
      );
    },
    deleteDepartment() {
      this.$store.dispatch("api/del_departments", this.model.id).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          var idx = this.departments.indexOf(this.model);
          this.departments.splice(idx, 1);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Record deleted successfully",
          });
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Failed to delete record.",
          });
          console.log(error);
        }
      );
    },
  },
};
</script>
<style></style>
