export default {
  methods: {
    logout() {
      this.$store.dispatch("api/logout", this.model).then(
        () => {
          this.$router.push("/login");
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
          this.$router.push("/");
        }
      );
    },
  }
};