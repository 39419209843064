<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row justify-content-between">
        <div class="col-xl-4 col-lg-6" v-show="user.role_id > 1">
          <stats-card
            title="Registered Students"
            type="gradient-red"
            :sub-title="data.student_stats.registered"
            icon="ni ni-circle-08"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div class="row">
                <div class="col-xl-6 col-lg-6">
                  <span class="text-success mr-2">
                    {{ data.student_stats.approved }}
                  </span>
                  <span class="text-success text-nowrap">Approved</span>
                </div>

                <div class="col-xl-6 col-lg-6">
                  <span class="text-primary mr-2">
                    {{ data.student_stats.pending }}
                  </span>
                  <span class="text-primary text-nowrap">Pending</span>
                </div>
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-show="user.role_id > 1">
          <stats-card
            title="Registered Supervisors"
            type="gradient-green"
            :sub-title="data.supervisor_stats.registered"
            icon="ni ni-circle-08"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div class="row">
                <div class="col-xl-6 col-lg-6">
                  <span class="text-success mr-2">
                    {{ data.supervisor_stats.approved }}
                  </span>
                  <span class="text-success text-nowrap">Approved</span>
                </div>

                <div class="col-xl-6 col-lg-6">
                  <span class="text-primary mr-2">
                    {{ data.supervisor_stats.pending }}
                  </span>
                  <span class="text-primary text-nowrap">Pending</span>
                </div>
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Submitted Cases"
            type="gradient-blue"
            :sub-title="data.cases.submitted"
            icon="ni ni-briefcase-24"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <div class="row">
                <div class="col-xl-6 col-lg-6">
                  <span class="text-success mr-2">
                    {{ data.cases.reviewed }}
                  </span>
                  <span class="text-success text-nowrap">Reviewed</span>
                </div>

                <div class="col-xl-6 col-lg-6">
                  <span class="text-primary mr-2">
                    {{ data.cases.pending }}
                  </span>
                  <span class="text-primary text-nowrap">Pending</span>
                </div>
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7" v-show="user.role_id > 1">
      <div class="row justify-content-left">
        <div class="col-xl-4">
          <card shadow type="secondary" v-if=" data.studentRegByYear !== undefined && data.studentRegByYear.length!=0 " >
             <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">Registration By Year</h3>
                    </div>
                  </div>
                </div>
              </template>
              <div class="list-group">
                 <div
                  v-for="(value, name) in data.studentRegByYear"
                  :key="value"
                  class="list-group-item list-group-item-action"
                >
                  <span class="font-weight-bold">{{ name }}</span>
                  <span class="float-md-right">{{ value }}</span>
                </div>
              </div>
          </card>
          <content-loader v-else></content-loader>
        </div>
        <div class="col-xl-8"  v-if="is_admin">
          <card shadow type="secondary">
            <div
              class="table-responsive"
              v-if="data.supervisor_case_stats"
              >
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                tbody-classes="list"
                :data="data.supervisor_case_stats"
              >
                <template v-slot:columns>
                  <th class="text-left">Supervisor Name </th>
                  <th class="text-right text-success">Reviewed</th>
                  <th class="text-right text-danger">Pending</th>
                  <th class="text-right">Submitted</th>
                </template>

                <template v-slot:default="row">
                  <th scope="row" class="text-left">
                    {{ row.item.supervisor.first_name }}
                    {{ row.item.supervisor.last_name }}
                  </th>
                  <td class="text-right text-success">
                    {{
                      row.item.reviewed != undefined ? row.item.reviewed : "-"
                    }}
                  </td>
                  <td class="text-right text-danger">
                    {{ row.item.pending != undefined ? row.item.pending : "-" }}
                  </td>
                  <td class="text-right">
                    {{ row.item.submitted }}
                  </td>
                </template>
              </base-table>
            </div>
             <content-loader v-else></content-loader>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ContentLoader } from "vue-content-loader";
export default {
  name: "dashboard",
  components: {
    ContentLoader,
  },
  data() {
    return {
      data: {
        student_stats: {
          registered: 0,
          approved: 0,
          pending: 0,
        },
        supervisor_stats: {
          registered: 0,
          approved: 0,
          pending: 0,
        },
        cases: {
          submitted: 0,
          reviewed: 0,
          pending: 0,
        },
        studentRegByYear: [],
      },
    };
  },
  mounted: function () {
    this.getStats();
  },
  methods: {
    getStats: function () {
      this.$store.dispatch("api/get_stats").then(
        (response) => {
          this.data = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
  },
  computed: {
    user() {
      return this.$store.state.api.user;
    },
    is_admin() {
      return this.$store.state.api.is_admin;
    },
  },
};
</script>
<style></style>
