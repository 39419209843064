<template>
  <div class="vld-parent">
    <base-nav
      class="navbar-top navbar-dark"
      id="navbar-main"
      :show-toggle-button="false"
      expand
    >
      <ul
        class="navbar-nav align-items-center mr-3 d-none d-md-flex ml-lg-auto"
      >
        <li class="nav-item dropdown">
          <base-dropdown class="nav-link pr-0">
            <template v-slot:title>
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  {{ initials }}
                </span>
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm font-weight-bold">{{
                    user.displayName
                  }}</span>
                </div>
              </div>
              <hr class="my-3" />
              <div
                class="media align-items-center"
                v-show="
                  user.department[0] != undefined &&
                  user.department[0].academic_year != null
                "
              >
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm"
                    >Year:
                    {{
                      user.department[0] != undefined &&
                      user.department[0].academic_year != null
                        ? user.department[0].academic_year.name
                        : ""
                    }}</span
                  >
                </div>
              </div>
              <div class="media align-items-center">
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-lg font-weight-bold"
                    >Acc. No. {{ user.accountNumber }}</span
                  >
                </div>
              </div>
            </template>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My Account</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/password" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>Change password</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0)" class="dropdown-item" @click="logout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </li>
      </ul>
    </base-nav>
  </div>
</template>
<script>
import store from "../store";
import logout from "../mixins/logout";
export default {
  mixins: [logout],
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      user: null,
      initials: "",
      fullPage: true,
    };
  },
  created() {
    this.user = store.state.api.user;

    this.initials =
      store.state.api.user.firstName.charAt(0).toUpperCase() +
      " " +
      store.state.api.user.lastName.charAt(0).toUpperCase();
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
