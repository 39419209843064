<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
        <div
          class="card-header border-0"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <div class="row align-items-center">
            <div class="col-sm-12">
              <base-alert v-if="show_alert" :type="alert_type" dismissible>
                <div v-for="(row, index) in alert_response" :key="row">
                  <span class="alert-inner--icon"
                    ><i class="fa fa-exclamation"></i
                  ></span>

                  <span class="alert-inner--text"
                    ><strong>{{ index }}</strong> {{ row }}</span
                  >
                </div>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4">
              <el-select
                class="mb-3"
                style="width: 100%"
                placeholder="Select Year"
                v-model="model.academic_year_id"
                filterable
                :value="model.academic_year_id"
                @change="academicYearSelect"
              >
                <el-option
                  v-for="option in selects.years"
                  class="select-danger"
                  :value="option.id"
                  :label="option.name"
                  :key="option"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col text-right">
              <base-button type="success" @click="download" size="sm"
                >Download <i class="ni ni-cloud-download-95 text-white"></i
              ></base-button>
            </div>
          </div>

          <div class="view">
            <div class="wrapper table-responsive">
              <table
                class="table align-items-center table-light"
                v-if="model.academic_year_id != null"
              >
                <thead class="thead-light">
                  <tr>
                    <th class="sticky-col-1 first-col">First Name</th>
                    <th class="sticky-col-1 second-col">Last Name</th>
                    <th class="sticky-col-1 third-col">Total Score</th>
                    <th
                      v-for="activity in model.activities"
                      :key="activity.id"
                      v-html="activity.name"
                    ></th>
                  </tr>
                  <tr>
                    <th class="sticky-col-1 first-col" colspan="3">
                      Min. Case : Score per case : Max. Score
                    </th>
                    <th
                      v-for="activity in model.activities"
                      :key="activity.id"
                      v-html="
                        results['activity_total'] !== undefined &&
                        results['activity_total'][activity.id] !== undefined
                          ? results['activity_total'][activity.id]['min_case'] +
                            ' : ' +
                            results['activity_total'][activity.id][
                              'score_per_case'
                            ] +
                            ' : ' +
                            results['activity_total'][activity.id][
                              'total_max_score'
                            ]
                          : ''
                      "
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="student in students" :key="student.id">
                    <th class="sticky-col first-col">
                      {{ student.firstName }}
                    </th>
                    <th class="sticky-col second-col">
                      {{ student.lastName }}
                    </th>
                    <th class="sticky-col third-col">
                      <div class="row">
                        <div class="col mt-2">
                          {{
                            results["users"] !== undefined &&
                            results["users"][student.id] !== undefined &&
                            results["users"][student.id]["final_score"] > 0
                              ? results["users"][student.id]["final_score"] +
                                " / " +
                                results["users"][student.id][
                                  "total_max_score_total"
                                ]
                              : "0"
                          }}
                          {{
                            results["users"] !== undefined &&
                            results["users"][student.id] !== undefined &&
                            results["users"][student.id]["final_score"] > 0
                              ? "( " +
                                Math.round(
                                  (results["users"][student.id]["final_score"] /
                                    results["users"][student.id][
                                      "total_max_score_total"
                                    ]) *
                                    100
                                ) +
                                "% )"
                              : "0"
                          }}
                          <br />
                          <base-button
                            type="danger"
                            @click="downloadResultPdf(student.id)"
                            size="sm"
                            >PDF</base-button>
                          <base-button
                            type="success"
                            @click="downloadResultExcel(student.id)"
                            size="sm"
                            >Excel</base-button>
                        </div>
                      </div>
                    </th>
                    <td v-for="activity in model.activities" :key="activity.id">
                      <div
                        :class="
                          results['results'] !== undefined &&
                          results['results'][student.id] !== undefined &&
                          results['results'][student.id][activity.id] !=
                            undefined &&
                          results['results'][student.id][activity.id][
                            'count'
                          ] != '0'
                            ? 'scored p-1'
                            : 'p-1'
                        "
                      >
                        <div class="row">
                          <div class="col">Count:</div>
                          <div class="col">
                            {{
                              results["results"] !== undefined &&
                              results["results"][student.id] !== undefined &&
                              results["results"][student.id][activity.id] !=
                                undefined
                                ? results["results"][student.id][activity.id][
                                    "count"
                                  ]
                                : "-"
                            }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">Total Score:</div>
                          <div class="col">
                            {{
                              results["results"] !== undefined &&
                              results["results"][student.id] !== undefined &&
                              results["results"][student.id][activity.id] !=
                                undefined
                                ? results["results"][student.id][activity.id][
                                    "total_score"
                                  ]
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import RepoService from "../../services/repository.service";
export default {
  name: "results-table",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      isDisabled: true,
      students: [],
      results: [],
      model: {
        activities: [],
        tool_id: null,
        academic_year_id: null,
        isvalid: 1,
      },
      selects: {
        simple: "",
        years: [],
        year_array: {},
      },
    };
  },
  mounted: function () {
    this.getYears();
  },
  computed: {
    alert_response() {
      return this.$store.state.api.api_response;
    },
    show_alert() {
      return this.$store.state.api.show_alert;
    },
    alert_type() {
      return this.$store.state.api.alert_type;
    },
  },
  methods: {
    download() {
      RepoService.downloadResults(this.model.academic_year_id)
        .then((Response) => {
          this.$swal("Success! Your report is downloading.", "", "success");
          let fileURL = window.URL.createObjectURL(
            new Blob([Response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "results_" + Math.round(+new Date() / 1000) + ".xlsx"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
          this.$swal(
            "Whoops! something went wrong downloading report",
            "",
            "error"
          );
        });
    },
    downloadResultPdf(sid) {
      this.$swal(
        "Success!",
        "Your report will be downloading shortly. Please wait...",
        "success"
      );
      RepoService.downloadResultPdf(sid, this.model.academic_year_id)
        .then((Response) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([Response.data], {
              type: "application/pdf",
            })
          );
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "result_" + Math.round(+new Date() / 1000) + ".pdf"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
          this.$swal(
            "Whoops! something went wrong downloading report",
            "",
            "error"
          );
        });
    },
    downloadResultExcel(sid) {
      this.$swal(
        "Success!",
        "Your report will be downloading shortly. Please wait...",
        "success"
      );
      RepoService.downloadResultExcel(sid, this.model.academic_year_id)
        .then((Response) => {
          this.$swal("Success! Your report is downloading.", "", "success");
          let fileURL = window.URL.createObjectURL(
            new Blob([Response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "result_" + Math.round(+new Date() / 1000) + ".xlsx"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
          this.$swal(
            "Whoops! something went wrong downloading report",
            "",
            "error"
          );
        });
    },

    academicYearSelect(val) {
      this.model.academic_year_id = val;
      this.students = [];
      this.results = [];
      this.getActivities();
    },
    getYears() {
      this.$store.dispatch("api/get_years").then(
        (response) => {
          this.selects.years = response.data;
          if (this.$store.state.api.academic_year_id != null) {
            this.academicYearSelect(
              this.$store.state.api.user.department[0].academic_year.id
            );
          }
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getActivities() {
      this.$store.dispatch("api/get_activities").then(
        (response) => {
          this.tableData = [];
          this.model.activities = [];
          this.generateActivityList(response.data.data, "");
          this.getStudents();
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    getStudents() {
      this.$store
        .dispatch("api/get_students", this.model.academic_year_id)
        .then(
          (response) => {
            this.students = response.data;
            this.getResults();
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            console.log(error);
          }
        );
    },
    getResults() {
      this.$store.dispatch("api/get_results", this.model.academic_year_id).then(
        (response) => {
          this.results = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },

    generateActivityList(activities, indent) {
      activities.forEach((activity) => {
        var _name = indent + activity.label;
        var _id = activity.value;
        var tool = activity.tool != undefined ? activity.tool.name : "";
        if (activity.children === undefined) {
          this.model.activities.push({
            id: _id,
            name: tool + "</br> " + _name,
          });
        }
        if (activity.children !== undefined) {
          this.generateActivityList(activity.children, _name + "</br> ");
        }
      });
    },
  },
};
</script>
<style>
.wrapper {
  position: relative;
  overflow: auto;
  border: 1px solid #e8eaed;
  white-space: nowrap;
  height: 900px;
  overflow: auto;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  z-index: 1 !important;
}
.sticky-col-1 {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  z-index: 3 !important;
}
.first-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 100px;
}
.third-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 250px;
}
.scored {
  background-color: greenyellow;
}
</style>
