<template>
  <div class="main-content bg-default">
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white">Welcome to LEXMA!</h1>
              <p v-if="!hasError" class="text-lead text-white">
                Please wait as we verify your token and log you in .....
              </p>

              <base-alert v-if="hasError" :type="danger" dismissible>
                <span class="alert-inner--icon"
                  ><i class="fa fa-exclamation"></i
                ></span>

                <span class="alert-inner--text"
                  ><strong>Error</strong> {{ message }}</span
                >
              </base-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      hasError: false,
      message: "",
      name: "",
    };
  },
  mounted() {
    this.name = this.$route.name;
    this.loginUser(this.$route.query.token);
  },
  methods: {
    loginUser: function (token) {
      this.$store.dispatch("api/verify", token).then(
        () => {
          this.$store.dispatch("api/get_my_departments").then(
            // eslint-disable-next-line no-unused-vars
            (response) => {
              if (response.data.length) {
                if (this.name == "review_join_request")
                  this.$router.push("/users");
                else if (this.name == "manage_case")
                  this.$router.push("/manage-case");
                else this.$router.push("/dashboard");
              } else {
                this.$router.push("/user-departments");
              }
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
              this.$router.push("/dashboard");
            }
          );
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          this.message = error.response.data.message;
          this.hasError = true;
        }
      );
    },
  },
};
</script>
<style></style>
