<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="..." />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a
                class="nav-link nav-link-icon"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ni ni-bell-55"></i>
              </a>
            </template>

            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </base-dropdown>
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a class="nav-link" href="#" role="button">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img
                      alt="Image placeholder"
                      src="img/theme/team-1-800x800.jpg"
                    />
                  </span>
                </div>
              </a>
            </template>

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#!" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>
        <span class="text-sm">{{ selected_department }}</span>
        <hr class="my-3" />
        <base-dropdown>
          <template v-slot:title>
            <base-button
              type="primary"
              icon="ni ni-bold-right"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              size="sm"
              @click="getMyDepartments"
              >Switch Department</base-button
            >
          </template>

          <span class="ml-3 text-sm">Choose Department</span>
          <div class="dropdown-divider"></div>
          <span v-for="department in departments" :key="department.id">
            <a
              v-if="department.is_approved"
              class="dropdown-item text-success"
              href="javascript:void(0)"
              @click="switchDepartment(department)"
            >
              ● {{ department.name }}</a
            >
          </span>
        </base-dropdown>
        
         <ul class="navbar-nav">
          <sidebar-item
            :link="{
              name: 'Join Department',
              icon: 'ni ni-building text-blue',
              path: '/user-departments',
            }"
          />
        </ul>
        <hr class="my-3" />
        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <ul class="navbar-nav">
          <sidebar-item
            :link="{
              name: 'My Account',
              icon: 'ni ni-single-02',
              path: '/profile',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Change password',
              icon: 'fa fa-unlock-alt',
              path: '/password',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Bills',
              icon: 'ni ni-money-coins',
              path: '/bills',
            }"
          />
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" @click="logout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import logout from "../../mixins/logout";
export default {
  name: "sidebar",
  mixins: [logout],
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/logo.png",
      description: "Lexma",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      selected_department: null,
      departments: null,
    };
  },
  mounted() {
    this.selected_department = this.$store.state.api.selected_department;
  },
  methods: {
    switchDepartment(department) {
      this.$store.dispatch("api/switch_department", department).then(
        // eslint-disable-next-line no-unused-vars
        () => {
          this.selected_department = department.name;
          this.$router.push("/");
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },

    getMyDepartments() {
      this.$store.dispatch("api/get_my_departments").then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          this.departments = response.data;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log(error);
        }
      );
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },

  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
